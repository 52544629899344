import React from 'react'
import { Box, Button, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { formatHours, formatFulldate } from '../helpers/date'
import { formatPrice } from '../helpers/price'
// import { getUserActive } from '../service/localStorage'

const mediaQuery = "@media (max-width: 858px)";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#252525",
    borderRadius: 16,
  },
  mainContainer: {
    [mediaQuery]: {
      padding: 0,
    }
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [mediaQuery]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  valor: {
    [mediaQuery]: {
      textAlign: 'center',
      marginTop: '20px'
    }
  }
});

const Amount = ({ setTicket, ticket, game }) => {
  //console.log('-------------', getUserActive())
  //getUserActive('true') ? console.log('User is active') : window.location.reload()
  //console.log(getUserActive())
  const classes = useStyles()
  const [quantity, setQuantity] = React.useState(1)

  const pad = (d) => (d < 10) ? '0' + d.toString() : d.toString()

  const add = () => {
    setQuantity(quantity + 1)
  }

  const subtract = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  return (
    <Box p={5} mt={2} className={classes.mainContainer}>
      <Box p={5} className={classes.root}>
        <Box>
          <Typography   style={{ fontFamily: 'Work Sans'}} align="center" variant="h2" color="textPrimary">
            {formatHours(game?.startTime)}
          </Typography>
          <Typography   style={{ fontFamily: 'Work Sans'}} align="center" variant="h4" color="textPrimary">
            {formatFulldate(game?.startTime)}
          </Typography>
        </Box>
        <Box mt={3} className={classes.infoContainer}>
          <Box>
            <Typography   style={{ fontFamily: 'Work Sans'}} variant="h5" color="primary">Transporte</Typography>
            <Typography   style={{ fontFamily: 'Work Sans'}} variant="body1" color="textPrimary">
              {ticket.transport === 'car' && 'Carro'}
              {ticket.transport === 'van' && 'Van'}
              {ticket.transport === 'bus' && 'Ônibus'}
            </Typography>
          </Box>
          <Box>
            <Typography   style={{ fontFamily: 'Work Sans'}} variant="h5" color="primary" align="right" className={classes.valor}>
              R$ {formatPrice((ticket.transport === 'car' && game.carPrice) || (ticket.transport === 'van' && game.vanPrice) || (ticket.transport === 'bus' && game.busPrice))}
            </Typography>
            <Typography   style={{ fontFamily: 'Work Sans'}} variant="body1" color="textPrimary">
              valor do ingresso
            </Typography>
          </Box>
        </Box>

        <Box mt={3} display="flex" justifyContent="center">
          <Box p={2}>
            <IconButton aria-label="remove" color="primary" onClick={subtract}>
              <RemoveIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box p={2} display="flex" alignItems="center">
            <Typography   style={{ fontFamily: 'Work Sans'}} variant="h4" color="textPrimary">{pad(quantity)}</Typography>
          </Box>
          <Box p={2}>
            <IconButton aria-label="add" color="primary" onClick={add}>
              <AddIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Typography   style={{ fontFamily: 'Work Sans'}} align="center" variant="subtitle1" color="textPrimary">Quantidade</Typography>
        </Box>
        <Box mt={5} display="flex" justifyContent="center">
          <Box minWidth={350} display="flex" justifyContent="center">
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => { setTicket({ ...ticket, 'quantity': quantity })}}
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#000000', fontWeight: 'bold', borderRadius: 50, width: '80%'}}
            >
              Comprar
          </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Amount
