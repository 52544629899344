import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
    root: {
        paddingBottom: '30px',
        //alignItems: 'baseline',
        [mediaQuery]: 
        {   
            marginTop: '0',
            paddingBottom: '0'
        }
    },
  });

const ContentWrap = ({ children }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root} mt={9} width="100%" display="flex">{children}</Box>
    )

}

export default ContentWrap
