import { Grid, GridListTile, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { GameCard } from '../components';
import api from '../service/api';
import { makeStyles } from '@material-ui/styles';

const mediaQuery = "@media (max-width: 858px)"

const useStyles = makeStyles({
  title: {
    [mediaQuery]:{
      paddingLeft: '24px',
      marginBottom: '24px',
      width: '100%'
    }
  }
});

const SearchResults = (props) => {
  const classes = useStyles();
  const [allEvents, setAllEvents] = useState(props.events ? props.events : []);
  const [search, setSearch] = useState(props.searchString);

  useEffect(() => {
    if(props.searchString !== ''){
      setSearch(props.searchString)
    }
  }, [props.searchString])

  useEffect(() => {
    async function getSearchResults() {
      const res = await api.get(`event/search?name=${search}`);
      setAllEvents(res.data.events);
    }
    if(props.events) {
      setAllEvents(props.events)
    } else {
      getSearchResults();
    }
   }, [search]);

  return (
    <>
      <Grid container spacing={1} >
        <Grid>

        </Grid>
        <GridListTile className={classes.title}>
          <Typography variant="h5" color="primary"
            style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontSize: 16, color: '#FFFFFF'}}
            >
            {allEvents.length !== 0 ? 'Resultado da pesquisa:  ' : 'Nenhuma pesquisa encontrada para: '}
            </Typography>
            <Typography variant="h5" color="primary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontSize: 16, fontWeight: 600}}
              >
                {`  ${search}`}
            </Typography>
        </GridListTile>
      </Grid>
        <Grid container className="responsive-grid" >
          {allEvents.map(game => (
                <GameCard key={game._id} game={game.permittedEvent ? game.permittedEvent : game}  />
              ))}              
        </Grid>
    </>
  );
 };

export default SearchResults;