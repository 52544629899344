
import React from 'react'

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import exemplo from '../assets/img/exemplo.jpg'
import { formatFulldate, formatHours, formatSemDate } from '../helpers/date';
import { formatPrice } from '../helpers/price';

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
  media: {
    height: 321,
  },
  colorCard: {
    backgroundColor: '#252525',
    [mediaQuery]: {
      padding: 0,
    }
  },
  main: {
    padding: '24px',
    [mediaQuery]: {
      padding: 0,
    }
  },
  contentRow: {
    [mediaQuery]: {
      flexDirection: 'column',
      marginTop: 0
    }
  },
  title: {
    [mediaQuery]: {
      marginBottom: '10px'
    }
  },
  lastRow: {
    [mediaQuery]: {
      textAlign: 'center',
      flexDirection: 'column'
    }
  },
  voltarBtnContainer: {
    width: '50%',
    margin: '0 auto',
    marginTop: '32px',
  }
});

const MyGameCardDetails = ({ moreDetail, btnVoltar, onVoltar }) => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Card className={classes.colorCard}>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          image={moreDetail?.permittedEvent?.image || exemplo}
          className={classes.media}
        />
        <CardContent >
          <Box p={2}>
            <Typography style={{ fontFamily: 'Work Sans' }}
              variant="h5" color="primary">
              {moreDetail?.eventName}
            </Typography>

            <Typography style={{ fontFamily: 'Work Sans' }}
              variant="body2" color="textSecondary" component="p">
              {moreDetail?.permittedEvent?.description}
            </Typography>
            <Box display="flex" justifyContent="space-between" mt={2} className={classes.contentRow}>
              <Box className={classes.title}>
                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="subtitle2" color="textPrimary">
                  {moreDetail?.permittedEvent?.local}
                </Typography>

                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="body2" color="textSecondary" component="p">
                  {moreDetail?.permittedEvent?.complement}
                </Typography>
              </Box>
              <Box>
                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="subtitle2" color="textPrimary">
                  Total
                </Typography>

                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="body2" color="textSecondary" component="p">
                  R$ {formatPrice(moreDetail?.permittedEvent?.carPrice)}
                </Typography>
              </Box>
              <Box>
              </Box>

            </Box>
            <Box display="flex" justifyContent="space-between" mt={2} className={classes.contentRow}>
              <Box>
                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="subtitle2" color="textPrimary">
                  {formatFulldate(moreDetail?.permittedEvent?.startTime)} - {formatSemDate(moreDetail?.permittedEvent?.startTime)}
                </Typography>

                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="body2" color="textSecondary" component="p">
                  {formatHours(moreDetail?.permittedEvent?.startTime)}
                </Typography>
              </Box>
              <Box>
                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="subtitle2" color="textPrimary">
                  Forma de pagamento
                </Typography>

                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="body2" color="textSecondary" component="p">
                  {moreDetail?.type === 'giveAway' ? 'Cortesia ' : 'Cartão de crédito'} {moreDetail?.creditCardBrand}
                </Typography>
                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="body2" color="textSecondary" component="p">
                  {moreDetail?.creditCardNumber}
                </Typography>
              </Box>
              <Box>
                <Typography style={{ fontFamily: 'Work Sans'}}
                  variant="subtitle2" color="textPrimary" component="p">
                  Pedido
                </Typography>
                <Typography style={{ fontFamily: 'Work Sans'}}
                  variant="body2" color="textSecondary" component="p"> 
                  {moreDetail?.orderNumber}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2} className={classes.contentRow}>
              <Box>
                <Typography style={{ fontFamily: 'Work Sans'}}
                  variant="subtitle2" color="textPrimary" component="p">
                  Veículo  
                </Typography>
                <Typography style={{ fontFamily: 'Work Sans'}}
                  variant="body2" color="textSecondary" component="p"> 
                  {moreDetail?.vehicle_type === 'car' && 'Carro'}
                  {moreDetail?.vehicle_type === 'van' && 'Van'}
                  {moreDetail?.vehicle_type === 'bus' && 'Ônibus'}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} className={classes.lastRow}>
              <Box>
                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="subtitle1" color="primary">
                  * Para usar o ingresso,
                </Typography>
                <Typography style={{ fontFamily: 'Work Sans' }}
                  variant="subtitle1" color="primary">baixe o app</Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-evenly" alignItems="center" marginTop={0} className={classes.contentRow}>

                <a href='https://play.google.com/store/apps/details?id=com.plax.parking&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noreferrer" style={{padding: '5px 0'}}>
                  <img width="150px" alt='Disponível no Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png'/>
                </a>

                <a href="https://apps.apple.com/br/app/fast-parking/id1549229777?itsct=apps_box&amp;itscg=30200" style={{display: 'inline-block', overflow: 'hidden', borderRadius: '13px', padding: '5px 0'}} target="_blank" rel="noreferrer">
                  <img width="120px" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=250x83&amp;releaseDate=1613347200&h=aad69a80b849ae322b174592d78f3b40" alt="Download on the App Store"/>
                </a>

              </Box>
              <Box>
              </Box>
            </Box>
            {btnVoltar ? 
              <Box className={classes.voltarBtnContainer}>
                <Button onClick={onVoltar} color="primary" fullWidth variant="outlined">Voltar</Button>
              </Box>
              : null
            }
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default MyGameCardDetails