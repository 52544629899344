import { makeStyles } from "@material-ui/core";
import React from "react";
import fastParking from "../assets/icons/logo_branca.png";

import "../styles/home.css";

const useStyles = makeStyles({
  soon: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "30px",
    flexDirection: "column",
  },
  soonlogo: {
    width: "200px",
    marginBottom: "35px",
  },
  apps: {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

const Soon = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.soon}>
        <img
          className={classes.soonlogo}
          src={fastParking}
          alt="Fast Parking"
        />
        <div className={classes.apps}>
          <div>
            <a
              href="https://apps.apple.com/us/app/fast-parking/id1549229777?itsct=apps_box_badge&amp;itscg=30200"
              style={{
                display: "inline-block",
                overflow: "hidden",
                "border-radius": "13px",
                width: "250px",
                height: "83px",
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=250x83&amp;releaseDate=1613347200"
                alt="Download on the App Store"
                style={{
                  "border-radius": "13px",
                  width: "250px",
                  height: "83px",
                }}
              />
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.plax.parking&hl=pt_BR&gl=US&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              style={{
                display: "inline-block",
                overflow: "hidden",
                "border-radius": "13px",
                width: "250px",
                height: "107px",
              }}
            >
              <img
                alt="Disponível no Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
                style={{
                  width: "250px",
                  height: "107px",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Soon;
