import React, { useState } from "react";

import { Navbar, NavbarBrand } from "reactstrap";

import "../styles/navBar.css";

import { FaBars } from "react-icons/fa";

import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import fastParking from "../assets/icons/logo_branca.png";
import "../styles/navBar.css";

import { useEffect } from "react";

import history from "../history";

const useStyles = makeStyles({
  bar: {
    height: "72px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#252525",
  },
  input: {
    backgroundColor: "#343434",
    borderRadius: 25,
  },
  inputSearch: {
    backgroundColor: "#343434",
    borderRadius: 25,
    padding: 20,
    width: 250,
    height: 8,
    border: "none",
    color: "#FFFFFF",
    marginRight: 20,
    fontFamily: "Work Sans",
  },
  buttonSearch: {
    position: "relative",
    background: "none",
    border: "none",
    color: "#FFFFFF",
    marginLeft: -45,
    right: 10,
  },
  homeIcon: {
    width: 30,
    height: 30,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
    right: 10,
  },
});

const NavBar = (props) => {
  const classes = useStyles();
  const [events, setEvents] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginVisible, setLoginVisible] = useState(false);
  const [registerVisible, setRegisterVisible] = useState(false);
  const [buscaVisible, setBuscaVisible] = useState(
    props.busca ? props.busca : true
  );
  const [isLoginOpened, setIsLoginOpened] = useState(false);

  const { busca, setSearchString } = props;
  //const history = useHistory();

  useEffect(() => {
    setBuscaVisible(busca);
  }, [busca]);

  useEffect(() => {
    onSearchChange(events);
    setSearchString(events);
  }, [events, onSearchChange, setSearchString]);

  // function handleSearch(e) {
  //   e.preventDefault();
  //   history.push(`/{$events)`});
  // }

  useEffect(() => {
    setBuscaVisible(!openMenu);
  }, [openMenu]);

  useEffect(() => {
    if (!isLoginOpened) {
      setLoginVisible(false);
    }
  }, [isLoginOpened]);

  function onSearchChange(searchString) {
    if (props.setSearchData) {
      props.setSearchData(searchString);
    }
    return;
  }

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  }

  function handleLoginClick() {
    setLoginVisible(true);
    setOpenMenu(false);
  }

  function handleRegisterClick() {
    setRegisterVisible(true);
    setOpenMenu(false);
  }

  const redirect = (path) => {
    history.push(path);
    history.go();
  };

  return (
    <div>
      <Navbar className={classes.bar}>
        <NavbarBrand style={{ width: "105px" }}>
          <IconButton
            style={{ padding: "0", borderRadius: "0", width: "100%" }}
            onClick={() => redirect("/")}
          >
            <img className="Logo" src={fastParking} alt="Fast Parking" />
          </IconButton>
        </NavbarBrand>
        <div>
          <IconButton onClick={handleMenuClick}>
            <FaBars className="barsIcon" />
          </IconButton>
        </div>
        {/* <input type="checkbox" id="check" />
        <label htmlFor="check" className="checkbtn" > */}
        {/* </label> */}
        {/*<Nav navbar className="NavbarResponsive">

          <div style={{display: 'flex', height: '100%'}}>

          
          <Box mr={3} maxHeight={1}>
            {buscaVisible ? 
              <div className="default">
                <form>
                  <input className={classes.inputSearch} placeholder="Buscar..." value={events} onChange={(e) => setEvents(e.target.value)} required/>
                  <button className={classes.buttonSearch} type="submit" ><SearchIcon /></button>
                </form>
              </div>
              :
              null
            }

          </Box>

          {getToken() ? (
            <>
              <div className="menu-default-usuario">
                <img style={{cursor: "pointer"}} onClick={() => redirect('/')} className={classes.homeIcon} src={homeICon} alt="Fast Parking" />
                <Box mr={2}>
                  <ButtonMaterial style={{ color: '#ffffff', textTransform: 'capitalize' }} onClick={() => redirect("/my-ticket")}>
                    Ingressos
                  </ButtonMaterial>
                </Box>
                <Avatar style={{cursor: "pointer"}} onClick={() => redirect('/show-profile')} alt="Avatar" src={avatarImg} />
              </div>

              <div className="menu-responsivo-usuario">
                <Menu style={{width: '100%'}} anchorEl={anchorEl} open={openMenu}>
                  <MenuItem 
                    onClick={() => {
                      setOpenMenu(false);
                      redirect('/');
                    }}
                  >
                    Home
                  </MenuItem>
                  <MenuItem 
                    onClick={() => {
                      setOpenMenu(false);
                      redirect('/my-ticket');
                    }}
                  >
                    Ingressos
                  </MenuItem>
                  <MenuItem 
                    onClick={() => {
                      setOpenMenu(false);
                      redirect('/show-profile');
                    }}
                  >
                    Minha Conta
                  </MenuItem>
                </Menu>
                <Login visible={loginVisible}/>
                <Register visible={registerVisible} />
            '</div>

            </>) :
            (<>
            <div className="menu-default-deslogado">
              <img onClick={() => redirect('/')} className={classes.homeIcon} src={homeICon} alt="Fast Parking" />
              <Login />
              <Register />
            </div>
            <div className="menu-responsivo-deslogado">
              <Menu style={{width: '100%'}} anchorEl={anchorEl} open={openMenu}>
                  <MenuItem 
                    onClick={() => {
                      setOpenMenu(false);
                      redirect('/');
                    }}
                  >
                    Home
                  </MenuItem>
                <MenuItem onClick={handleLoginClick}>Login</MenuItem>
                <MenuItem onClick={handleRegisterClick}>Cadastre-se</MenuItem>
              </Menu>
              <Login isLoginVisible={(value) => setIsLoginOpened(value)} visible={loginVisible}/>
              <Register visible={registerVisible} />
            </div>
            
            </>)
          }
          </div>
        </Nav>*/}
      </Navbar>
    </div>
  );
};

export default NavBar;
