import React from 'react';

import '../styles/emailVerified.css';
import checkImg from '../assets/icons/Ativo7.png';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import api from '../service/api';

const EmailVerified = () => { 
  const { token } = useParams();
  useEffect(() => {
    async function getUserStore() {
      try {
        await api.get(`user/store/${token}`);
      } catch (err) {
        console.log(err);
      }
    };
    getUserStore();
   }, [token]);
  return (
    <>
      <div className="container-verified">
        <div className="card">
          <h1 className="h1-verified">Parabéns!</h1>
          <div>
            <img className="img-verified" src={checkImg} alt="E-mail Verified"/>
            <p className="para-verified">Seu E-mail Foi verificado com Sucesso!</p>
            </div>
            <div className="link-verified">
              <Link className="link-button" to="/home">Ir para Fast Parking</Link>
            </div>
        </div> 
      </div>
    </>
  );
}

export default EmailVerified;