import "../styles/privacyPolicy.css";
export default function PrivacyPolicy() {
  return (
    <div className="container">
      <div className="container__header">
        <h1>Política de Privacidade</h1>
        <p>
          Esta Política de Privacidade foi atualizada pela última vez em 25 de
          Agosto de 2023.
        </p>
      </div>
      <div className="container__content">
        <p>
          A Easy Parking Eventos e Serviços LTDA (“Easy Parking") se compromete
          com a sua privacidade. A proteção à privacidade e aos dados pessoais
          refletem os nossos valores e reafirmam o nosso compromisso com a
          melhoria contínua da eficácia do processo de proteção de dados. Assim,
          nós apresentamos a presente política de privacidade (“Política de
          Privacidade”), cujo objetivo é informar, de maneira simples e
          transparente, a forma de tratamento dos dados pessoais dos titulares
          de dados pessoais que adquirem, ou podem eventualmente adquirir,
          ingressos na nossa plataforma (“Clientes” e “Potenciais Clientes”
          respectivamente, ou simplesmente “você”), desde a sua coleta até o seu
          descarte. A Plataforma da Easy Parking significa o website e o
          aplicativo utilizados para prestação dos serviços da empresa a você
          (“Plataforma”).
        </p>
        <p>
          É importante que você leia e compreenda essas regras, que devem ser
          interpretadas em conjunto com outras disposições aplicáveis,
          especialmente aquelas provenientes da legislação em vigor, incluindo a
          Lei Geral de Proteção de Dados (Lei Federal Nº 13,709/18 ou “LGPD”).
        </p>
        <p>
          ESTA POLÍTICA DE PRIVACIDADE SERÁ APLICÁVEL A PARTIR DE QUALQUER USO
          DA NOSSA PLATAFORMA OU DE NOSSOS SERVIÇOS. LEIA-A ATENTAMENTE E TENHA
          CERTEZA DE CONHECER AS DISPOSIÇÕES A SEGUIR ANTES DE USAR NOSSA
          PLATAFORMA E NOSSOS SERVIÇOS.
        </p>

        <h2>1. QUAIS DADOS PESSOAIS SÃO COLETADOS?</h2>
        <p>
          <strong>Dados Cadastrais:</strong> você nos fornecerá os seus dados
          pessoais para cadastro, que incluem: nome completo, telefone, data de
          nascimento, nacionalidade, gênero, CPF ou qualquer outro documento de
          identificação legal emitido pelo governo de seu país e e-mail.;
        </p>
        <p>
          <strong>Dados Adicionais de Identificação:</strong> poderemos coletar
          outros dados que nos ajudem a identificá-lo, como: endereço, documento
          de identificação (carteira de identidade, CNH, passaporte, documento
          profissional etc.);
        </p>
        <p>
          <strong>Dados Financeiros e da Compra:</strong> poderemos tratar dados
          bancários, dados de pagamento (incluindo foto de cartão de crédito),
          dados da compra do ingresso (como ID da transação). Também podem ser
          coletados dados de um terceiro proprietário do cartão de crédito
          utilizado para adquirir um ingresso na Plataforma;
        </p>
        <p>
          <strong>Outras Dados:</strong> poderemos realizar o tratamento de
          dados relacionados a geolocalização, localização (cidade e estado),
          interesses pessoais (por exemplo, eventos em que você tem interesse),
          dados do ingresso que foi ou será adquirido, foto (selfie),
          especificações do produto (por exemplo, tamanho de camiseta);
        </p>

        <h2>2. PARA QUAIS FINALIDADES COLETAMOS SEUS DADOS?</h2>
        <p>
          Os seus dados pessoais coletados são armazenados e tratados por nós
          para as finalidades elencadas abaixo, justificadas por uma das
          hipóteses legais previstas pela LGPD. Trataremos os seus dados
          pessoais para:
        </p>
        <p>
          <strong>
            realizar o seu cadastro na nossa Plataforma. viabilizar a compra de
            ingressos na Plataforma. Nesse sentido, poderemos:
          </strong>
          <ul style={{ marginLeft: 35 }}>
            <li>
              Permitir que suas transações de compra, a partir do seu cadastro,
              ocorram;
            </li>
            <li>
              Entrar em contato para informar que o pagamento referente a uma
              compra em processamento está pendente;
            </li>
            <li>
              Receber o pagamento e realizar a venda de ingressos físicos.
            </li>
          </ul>
        </p>
        <p>
          <strong>
            verificação e prevenção da ocorrência de fraudes. Nesse sentido,
            poderemos:
          </strong>
          <ul style={{ marginLeft: 35 }}>
            <li>
              Suspender ou bloquear uma transação com o objetivo de prevenir a
              ocorrência de fraudes no momento da compra de ingressos em nossa
              Plataforma;
            </li>
            <li>
              Comprovar a correspondência entre os dados utilizados para a
              compra e a sua identidade;
            </li>
            <li>
              Verificar a ocorrência de fraudes em compras, após detecção de
              indícios;
            </li>
            <li>
              Realizar um controle de qualidade do processo de prevenção a
              fraudes, além de realizar a última verificação de riscos das
              operações;
            </li>
            <li>
              Comprovar a correspondência entre os dados do cartão de crédito
              utilizado para realizar a compra na Plataforma e os dados do
              cartão de crédito físico apresentado por você.
            </li>
          </ul>
        </p>
        <p>
          <strong>prestar atendimento a você. Nesse sentido, poderemos:</strong>
          <ul style={{ marginLeft: 35 }}>
            <li>Realizar reembolsos solicitados por você;</li>
            <li>
              Transferir ingressos entre sua conta e a conta de outros Clientes;
            </li>
            <li>
              Prestar suporte técnico quando você reportar dificuldades para
              acessar alguma funcionalidade da nossa Plataforma ou precisar de
              informação sobre um ingresso adquirido;
            </li>
            <li>
              Viabilizar o contato dos produtores com você, dentro ou fora da
              nossa Plataforma;
            </li>
            <li>
              Confirmar sua identidade para fins de aprovação de alteração no
              cadastro ou realização de reembolso;
            </li>
            <li>
              Atender às suas solicitações feitas pela Plataforma de contato
              disponibilizada em nosso site ou por e-mail;
            </li>
            <li>
              Tomar medidas para viabilizar o seu acesso, caso esteja
              apresentando dificuldade na autenticação de dois fatores por meio
              do telefone.
            </li>
          </ul>
        </p>
        <p>
          <strong>
            desempenhar atividades relevantes para a condução das nossas
            atividades. Nesse sentido, poderemos:
          </strong>
          <ul style={{ marginLeft: 35 }}>
            <li>
              Controlar e mesmo permitir seu acesso ao evento, seja por meio de
              catracas ou manualmente;
            </li>
            <li>
              Anunciar promoções e eventos veiculados em nossa Plataforma;
            </li>
            <li>
              Desenvolver nossos produtos e serviços que envolvam parcerias com
              marcas e empresas que queiram interagir com você para realizar
              ofertas ou fazer campanhas de ativação, sempre que isso tenha
              relação com os interesses que você manifestamente demonstrou;
            </li>
            <li>Enviar newsletter e outros materiais de publicidade;</li>
            <li>
              Fazer a gestão de históricos de transações e de entradas em
              eventos;
            </li>
            <li>
              Realizar métricas, incluindo com o objetivo de providenciar
              soluções e/ou melhorias nas nossas atividades;
            </li>
            <li>
              Enviar atualizações acerca dos eventos para os quais foram
              adquiridos ingressos por meio da Plataforma;
            </li>
            <li>
              Enviar comunicações institucionais a respeito de cancelamentos,
              adiamentos e outras alterações relevantes nos eventos cujos
              ingressos foram comercializados na Plataforma.
            </li>
          </ul>
        </p>
        <p>
          <strong>
            viabilizar, quando necessário, que os produtores e parceiros
            conduzam os eventos e desenvolvam atividades conosco (sempre que
            possível, enviamos esses dados de forma agregada para os produtores,
            de modo que eles não tenham como te identificar diretamente). Nesse
            sentido, poderemos:
          </strong>
          <ul style={{ marginLeft: 35 }}>
            <li>
              Prestar suporte técnico para produtores que vendam ingressos para
              eventos em nossa Plataforma;
            </li>
            <li>
              Veicular informações, para os produtores, a respeito das métricas
              de marketing enviadas a você;
            </li>
            <li>
              Permitir acesso dos produtores às informações referentes aos
              eventos; Enviar relatório sobre estatísticas do evento ao
              produtor.
            </li>
          </ul>
        </p>

        <h2>3. COM QUEM OS DADOS PODEM SER COMPARTILHADOS?</h2>
        <p>
          Poderemos compartilhar seus dados pessoais com terceiros ou parceiros
          de negócios e faremos isso para viabilizar nossos serviços, para
          atender a um interesse legítimo nosso ou de terceiros (nesse caso,
          desde que seus direitos sejam observados), para cumprir obrigações
          legais ou regulatórias, para defender nossos direitos e em outras
          situações autorizadas por lei. Referido compartilhamento ocorre com
          base nos seguintes critérios e para as finalidades descritas abaixo.
        </p>
        <p>
          <strong>Prestadores de serviços.</strong> Os prestadores de serviço
          trabalham conosco para viabilizar nossas atividades, bem como para
          aprimorá-las. Dentre as finalidades para as quais compartilhamos dados
          com os prestadores de serviço estão: (i) gestão de e-mails
          operacionais e de marketing, (ii) gestão da nossa base de dados, (iii)
          criação de ações de marketing; (iv) operação de porta nos eventos; e
          (v) viabilização de verificação dos ingressos em catracas integradas,
          por meio de APIs.
        </p>
        <p>
          <strong>Fornecedores para verificação de fraudes.</strong> Os
          fornecedores de soluções de fraude prestam serviços e disponibilizam
          sistemas que nos auxiliam a verificar a ocorrência de fraudes no
          momento da compra de ingressos na Plataforma. Dentre as finalidades
          para as quais compartilhamentos dados com os fornecedores estão: (i)
          realização de análises de fraude em geral, inclusive por meio do
          cruzamento dos seus dados com bases de dados de fontes legítimas e
          públicas; (ii) avaliação da correspondência da sua identidade; e (iii)
          implementação de procedimentos de segurança para verificação da
          autenticidade de cartões de crédito.
        </p>
        <p>
          <strong>Produtores de eventos.</strong> Os produtores organizam as
          festas e eventos para os quais você compra ingressos em nossa
          Plataforma. Dentre as finalidades para as quais compartilhamos dados
          com os produtores estão: (i) disponibilização de dados relevantes para
          a condução/organização do evento pelo produtor; (ii) análise dos dados
          para fins de inteligência (por exemplo, para que o produtor possa
          entender melhor o público de determinada festa, os principais momentos
          das vendas dos ingressos, dentre outras situações). Sempre que
          possível, enviamos esses dados de forma agregada para os produtores,
          assim eles não conseguem te identificar diretamente); (iii)
          viabilização do contato direto entre os produtores e você; (iv)
          solução de problemas que exijam o envio de dados (por exemplo, quando
          houver qualquer erro técnico com seu ingresso e precisemos encontrar
          outras formas para garantir seu acesso ao evento); (v), confirmação da
          venda do ingresso para você; e (vi) viabilização de ações de marketing
          que tenham relação com os eventos para os quais você demonstrou
          interesse ou comprou ingressos .
        </p>
        <p>
          <strong>Com adquirentes.</strong> As adquirentes são entidades que
          credenciam empresas para aceitar instrumentos de pagamento (tais como
          cartão de crédito e cartão de débito) e, dentre outras atividades,
          participam da intermediação, do processamento e da liquidação das
          transações de pagamento realizadas na nossa Plataforma. Dentre as
          finalidades para as quais compartilhamos dados com as adquirentes
          estão: (i) cumprimento de obrigações contratuais; (ii) realização de
          reembolsos de acordo com nossas políticas; (iii) auxílio nas medidas
          de combate à fraude; e (iv) confirmação do pagamento do ingresso.
        </p>
        <p>
          <strong>Parceiros.</strong> Os parceiros são empresas ou indivíduos
          com os quais mantemos relações comerciais. Dentre as finalidades para
          as quais poderemos compartilhar seus dados pessoais com parceiros
          estão: (i) a viabilização da oferta de produtos e serviços; e (ii) a
          realização de ações de marketing. Essas ações só ocorrerão caso tenham
          relação com os eventos para os quais você demonstrou interesse ou
          comprou ingressos.
        </p>
        <p>
          <strong>Autoridades.</strong> As autoridades são as entidades públicas
          com as quais nos relacionamos no âmbito das nossas atividades por
          conta de alguma exigência legal ou regulatória. Dentre as finalidades
          para as quais poderemos compartilhar seus dados pessoais com
          autoridades estão: (i) cumprimento de requisição judicial ou
          administrativa, nos termos da lei; e (ii) defesa dos nossos interesses
          no âmbito de ações judiciais, administrativas ou arbitrais.
        </p>

        <h2>4. POR QUANTO TEMPO ARMAZENAMOS SEUS DADOS PESSOAIS?</h2>
        <p>
          Armazenamos e mantemos seus dados: (i) pelo tempo exigido por lei;
          (ii) até o término do tratamento de dados pessoais, conforme
          mencionado abaixo; ou (iii) pelo tempo necessário a preservar o nosso
          legítimo interesse (como, por exemplo, durante prazos prescricionais
          aplicáveis ou cumprimento de obrigação legal ou regulatória).
        </p>
        <p>
          O término do tratamento de dados pessoais ocorrerá quando for
          verificado:
          <ul style={{ marginLeft: 35 }}>
            <li>
              Que a finalidade pela qual os dados foram coletados foi alcançada
              ou que os dados pessoais coletados deixaram de ser necessários ou
              pertinentes ao alcance da finalidade específica almejada;
            </li>
            <li>
              Uma manifestação sua nesse sentido, para hipóteses em que nós
              recebemos o seu consentimento específico para determinado
              tratamento de dados pessoais; ou Determinação legal nesse sentido.
            </li>
          </ul>
        </p>
        <p>
          Nesses casos de término de tratamento de dados pessoais, ressalvadas
          as hipóteses estabelecidas pela legislação aplicável ou pela presente
          Política de Privacidade, os dados pessoais serão eliminados ou
          anonimizados.
        </p>

        <h2>5. SEUS DIREITOS</h2>
        <p>
          Você possui direitos em relação aos seus dados pessoais, os quais são
          assegurados pela LGPD e incluem:
        </p>
        <p>
          <strong>
            Possibilidade de confirmação da existência e acesso facilitado às
            informações referentes ao tratamento de dados pessoais.
          </strong>{" "}
          Mediante envio de requisição, você receberá a confirmação da
          existência do tratamento de seus dados pessoais e, quando aplicável,
          explicações sobre as atividades de tratamento, nos termos da
          legislação aplicável;
        </p>
        <p>
          <strong>Acesso aos dados pessoais.</strong> Você poderá requisitar
          acesso aos seus dados pessoais coletados e que estejam armazenados
          conosco;
        </p>
        <p>
          <strong>
            Correção de dados incompletos, inexatos ou desatualizados.
          </strong>{" "}
          Você poderá, a qualquer momento, alterar e editar os seus dados
          pessoais, fazendo o login na sua conta ou por meio de contato conosco;
        </p>
        <p>
          <strong>Dados sobre uso compartilhado de dados.</strong> As
          informações sobre o compartilhamento de dados pessoais encontram-se
          nesta Política de Privacidade.
        </p>
        <p>
          <strong>Revisão de decisão automatizada.</strong> Você poderá
          solicitar a revisão de decisões tomadas unicamente com base em
          tratamento automatizado de dados pessoais que afetem seus interesses,
          incluídas as decisões destinadas a verificar, por exemplo, a presença
          de fraudes na aprovação de suas compras no nosso site;
        </p>
        <p>
          <strong>
            Eliminação, anonimização e bloqueio dos dados pessoais.
          </strong>{" "}
          Você poderá solicitar a eliminação, anonimização e bloqueio de dados
          pessoais que tenham sido coletados por nós, incluindo a partir do seu
          consentimento (quando aplicável), a qualquer momento, mediante
          solicitação gratuita e facilitada. A eliminação, anonimização ou
          bloqueio dos dados pessoais somente poderá ser completada para dados
          pessoais que sejam excessivos ou não sejam necessários para que
          possamos cumprir com obrigações legais, contratuais, para proteção de
          seu legítimo interesse e nas demais hipóteses admitidas legalmente;
        </p>
        <p>
          <strong>Revogação do consentimento.</strong> Coletaremos seu
          consentimento em hipóteses bem limitadas. Ainda assim, quando
          aplicável, você poderá revogar o consentimento que tenha dado à Easy
          Parking para tratamento dos seus dados pessoais para certas
          finalidades, a qualquer momento, mediante manifestação gratuita e
          facilitada, por meio do canal a ser indicado quando da coleta do
          consentimento. Importante informar que os tratamentos realizados
          anteriormente à revogação do consentimento são ratificados e que o
          pedido de revogação não implicará a eliminação dos dados pessoais
          anteriormente tratados e que sejam mantidos por nós com base em outros
          fundamentos legais.
        </p>
        <p>
          Para exercer os direitos previstos acima, envie e-mail para{" "}
          <a
            href="mailto:administracao@easyparking.rio.br"
            style={{ color: "#ffd500" }}
          >
            administracao@easyparking.rio.br
          </a>
          .
        </p>

        <h2>6. COMO PROTEGEMOS SEUS DADOS PESSOAIS?</h2>
        <p>
          Nós levamos a sério nosso dever de proteger os dados que você confia a
          nós contra alteração, perda, uso indevido, divulgação ou acesso
          acidental ou não autorizado. Nós usamos diversas tecnologias de
          segurança e medidas técnicas e organizacionais para ajudar a proteger
          seus dados pessoais contra estes incidentes, como a imposição de
          restrição de acesso aos dados pessoais apenas àqueles colaboradores
          que tenham real necessidade de acessá-los e restrição de acesso e de
          utilização de dados pessoais a depender de quando foram coletados.
        </p>

        <h2>7. TRANSFERÊNCIAS INTERNACIONAIS DE DADOS PESSOAIS</h2>
        <p>
          Dentro das finalidades explicadas para você no item (2) acima, poderá
          ser necessária a transferência de alguns de seus dados pessoais a
          prestadores de serviços ou parceiros localizados no exterior,
          incluindo prestadores de serviços em nuvem. Nesse caso, adotaremos
          medidas apropriadas para garantir a proteção adequada de seus dados
          pessoais em conformidade com os requisitos da legislação aplicável de
          proteção de dados, incluindo a celebração de contratos de
          transferência de dados com terceiros de seus dados pessoais, quando
          necessário.
        </p>

        <h2>8. COOKIES</h2>
        <p>
          Cookies são pequenos arquivos de texto enviados ao computador do
          usuário que servem para reconhecer, acompanhar e armazenar a navegação
          do usuário na Internet. Essa tecnologia nos ajuda a entender melhor o
          comportamento dos Clientes, inclusive para fins de segurança e de
          prevenção de fraudes. Além disso, os cookies nos auxiliam a
          identificar quais seções dos nossos sites as clientes visitaram e
          contribuem para a eficácia das propagandas e pesquisas na Internet.
        </p>
        <p>
          Os cookies são tratados como dados não pessoais e dividem-se conforme
          as suas funcionalidades, entre funcionais, analíticos e de
          publicidade; bem como dividem-se conforme a sua permanência na
          máquina, entre permanentes ou de sessão.
        </p>
        <p>
          Nossa Plataforma poderá utilizar “cookies” que serão enviados ao
          browser e armazenados no disco do seu computador.
        </p>
        <p>
          Alguns cookies são necessários para o funcionamento da Plataforma e
          para a prestação dos nossos serviços a você. Esses cookies são,
          geralmente, definidos em resposta a ações feitas por você, tais como
          definir preferências de privacidade, fazer login, preencher
          formulários e selecionar um ingresso.
        </p>
        <p>
          Outra finalidade dos cookies é identificar suas atividades na nossa
          Plataforma. Esses cookies nos ajudam, por exemplo, a identificar áreas
          especialmente populares da nossa Plataforma e preferências e
          interesses seus. A partir deles, podemos adaptar o conteúdo da nossa
          Plataforma mais especificamente às suas necessidades e, assim,
          melhorar sua experiência e, eventualmente, facilitar sua navegação com
          as características, preferências e qualidade das informações
          veiculadas à sua navegação. Esses cookies também poderão ser usados
          para enviar informações publicitárias e promocionais relevantes para
          você, por exemplo com base nas páginas da web que você visitou, nos
          limites autorizados pela legislação em vigor.
        </p>
        <p>
          Nós atualmente não utilizamos uma solução técnica que nos permita
          responder plenamente aos sinais de “não rastrear” de seu navegador.
          Ainda assim, você pode gerenciar as configurações de cookies nas
          configurações de seu navegador a qualquer hora. Ao desabilitar todos
          os cookies nas configurações de seu navegador, é possível que certas
          seções ou recursos da Plataforma não funcionem ou demorem para
          carregar, pois seu navegador pode nos impedir de definir cookies
          necessários.
        </p>

        <h2>9. LINKS PARA PÁGINAS DE TERCEIROS</h2>
        <p>
          Podemos disponibilizar links para outras páginas na Internet, como,
          por exemplo, os sites de produtores de eventos ou seus perfis nas
          redes sociais. Não nos responsabilizamos pelas práticas de tratamento
          de dados pessoais dessas páginas externas à Plataforma. Caso haja
          interesse em acessá-las, recomendamos que você consulte as respectivas
          políticas de privacidade para se informar adequadamente a respeito do
          tratamento de seus dados pessoais.
        </p>

        <h2>10. ALTERAÇÕES NESTA POLÍTICA DE PRIVACIDADE</h2>
        <p>
          Reservamo-nos o direito de corrigir ou atualizar esta Política de
          Privacidade periodicamente. Ao atualizarmos esta Política de
          Privacidade, atualizaremos também a data na parte superior desta
          Política de Privacidade.
        </p>
        <p>
          Em situações relevantes, principalmente na eventual modificação das
          finalidades para os quais os dados tenham sido coletados, nós o
          informaremos a respeito das alterações realizadas. Salvo disposição em
          contrário, a nova Política de Privacidade entrará em vigor
          imediatamente após a publicação.
        </p>

        <h2>11. IDENTIFICAÇÃO DO CONTROLADOR E DO ENCARREGADO</h2>
        <p>
          A Easy Parking Eventos e Serviços LTDA, sociedade inscrita no CNPJ sob
          nº 17.113.299/0001-93, com sede na Rua México, 31, sala 703, Centro -
          RJ,{" "}
          <span style={{ textDecoration: "underline" }}>
            é a controladora dos dados pessoais
          </span>{" "}
          tratados no âmbito desta Política de Privacidade para as atividades da
          Plataforma do Fast Parking. Para entrar em contato com o encarregado
          pelo tratamento de dados pessoais (“
          <span style={{ textDecoration: "underline" }}>Encarregado</span>”),
          mande um e-mail para administracao@easyparking.rio.br.
        </p>

        <hr />

        <p>
          <strong>Em caso de dúvidas, você pode:</strong>
          <ul style={{ marginLeft: 35 }}>
            <li>Visitar a “Central de Dúvidas” do nosso App; ou</li>
            <li>
              Enviar um e-mail para nosso encarregado, conforme indicado acima.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}
