import React from 'react'
import { Button } from '@material-ui/core'


import '../styles/resetPassword.css';
import logoImg from '../assets/icons/logo_branca.png'
import { useHistory } from 'react-router-dom';
import { clear } from '../service/localStorage';
import { Typography } from '@material-ui/core';

const RegisterSuccess = () => {
  const history = useHistory();
  function goToHome(e) {
    e.preventDefault();
    clear();
    history.push('/');
  }
  return (
    <>
      <div className="container-reset">
        <div className="card">
          <img className="img-reset" src={logoImg} alt="Fast Parking"/>
          <Typography
                variant="h4"
                color="primary"
                style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 700 }}
              >
          Cadastro realizado
          </Typography>
          <Typography
                variant="h6"
                color="primary"
                style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#ffffff' }}
              >
          Enviamos um email para ativação do seu cadastro.
          </Typography>
          <div className="link-verified">
            <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} onClick={goToHome} variant="contained" size="large" color="primary" fullWidth>
              Ir para início
            </Button>
          </div>
        </div>
      </div>
    </>
  );
 };

export default RegisterSuccess;