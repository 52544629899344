import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { createRef } from "react";
import { usePopup } from "../contexts/PopupContext";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const mediaQuery = "@media (max-width:858px)";

const useStyle = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: '9999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    popup: {
        maxWidth: '450px',
        minWidth: '300px',
        minHeight: 'fit-content',
        backgroundColor: '#252525',
        zIndex:'99999',
        padding: '30px',
        borderRadius: '13px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        '& p': {
            color: 'white',
            fontSize: '20px',
            fontFamily: "'Work Sans', 'Regular roman'",
            textAlign: 'center',
            marginBottom: '0px'
        },
        [mediaQuery]: {
            maxWidth: '90%',
        }
    },
    icon: {
        width: '80px',
        height: '80px',
    },
    footer: {
        marginTop: '30px'
    }
})


const Popup = () => {
    const popupRef = createRef();

    const classes = useStyle();
    const {visible, setVisible, popup} = usePopup();

    const closePopup = () => {
        setVisible(false);
    }

    const checkIfClickedOutside = e => {
        if (visible && popupRef.current && !popupRef.current.contains(e.target)) {
          setVisible(false)
          if(popup.buttonAction) {
              popup.buttonAction()
          }
        }
    }

    return(
        <Box tabIndex={0} onClick={checkIfClickedOutside}  visibility={visible ? 'visible' : 'hidden'} className={classes.root}>
            <Box ref={popupRef} className={classes.popup}>
                {!popup.type ? null : popup.type === 'success' ?
                    <CheckCircleIcon className={classes.icon} color='primary' />
                    :
                    <ErrorIcon className={classes.icon} color='error' />    
                }
                <Box mt={!popup.type ? 0 : 3}>
                    <p>{popup.message}</p>
                </Box>

                {popup.extraContent}

                {popup.type ? 
                    <Box className={classes.footer}>
                        <Button onClick={popup.buttonAction ? popup.buttonAction : closePopup} variant='contained' color='primary' style={{borderRadius: 50}}>
                            {popup.buttonText ? popup.buttonText : 'Ok'}
                        </Button>
                    </Box>  
                    : 
                    null
                }
            </Box>
        </Box>
    )

}

export default Popup;