import React, {useState} from 'react'
import history from '../history';
import { getToken } from '../service/localStorage';
import api from '../service/api';
import jwt from 'jsonwebtoken';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

import { formatHours, formatFulldate } from '../helpers/date';
import Login from '../view/login';

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
  media: {
    height: 140,
  },
  colorCard: {
    backgroundColor: '#252525'
  },
  cardRoot: {
    borderRadius: 13,
    [mediaQuery]:{
      maxWidth: 'none !important'
    }
  }
});

const GameCard = ({ game }) => {
  const classes = useStyles();
  const [isLoginVisible, setLoginVisible] = useState(false);

  const redirect = (path) => {
    if (getToken()) {
      const { id } = jwt.decode(getToken('token')); 
      async function getUser() {
        const res = await api.get(`user/get?_id=${id}`)
        if (res.data.active === false) {
          history.push('/user/non-verificated')
        } else {
          history.push(path);
          history.go();
        }
      }
      getUser();
    } else {
      setLoginVisible(true);
    }
  }

  return (
    <Box className="g-card-cont" p={3}>
      <Card className={classes.cardRoot}
          style={{ maxWidth: 290}}
          >
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          image={game?.image}
          className={classes.media}
        />
        <CardContent className={classes.colorCard}>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" color="primary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 500}}
              >
                {game?.name}
              </Typography>
              <Typography variant="body1" color="textPrimary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
                {formatHours(game?.startTime)}
              </Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
              <Typography variant="body2" color="textSecondary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
                {game?.local}
              </Typography>
              <Typography variant="body2" color="textSecondary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
                {formatFulldate(game?.startTime)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <Button
          onClick={() => redirect(`/buy-ticket/${game?._id}`)}
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#000000', fontWeight: 'bold'}}
        >
          Comprar
        </Button>
      </Card>
      <Login callBtn="none" visible={isLoginVisible}/>
    </Box>
  )
}

export default GameCard
