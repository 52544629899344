export const setToken = token => {
  localStorage.setItem('token', token)
}

export const getToken = () => {
  return localStorage.getItem('token')
}

export const setSessionToken = token => {
  sessionStorage.setItem('token', token)
}

export const getSessionToken = () => {
  return sessionStorage.getItem('token')
}

export const clear = () => {
  localStorage.clear()
}

export const setRegisterName = name => {
  localStorage.setItem('name', name)
}

export const getRegisterName = () => {
  return localStorage.getItem('name')
}

export const setRegisterEmail = email => {
  localStorage.setItem('email', email)
}

export const getRegisterEmail = () => {
  return localStorage.getItem('email')
}

export const setRegisterPassword = password => {
  localStorage.setItem('password', password)
}

export const getRegisterPassword = () => {
  return localStorage.getItem('password')
}

export const setRegisterConfirmPassword = confirmPassword => {
  localStorage.setItem('confirmPassword', confirmPassword)
}

export const getRegisterConfirmPassword = () => {
  return localStorage.getItem('confirmPassword')
}

export const setRegisterCpf = cpf => {
  localStorage.setItem('cpf', cpf)
}

export const getRegisterCpf = () => {
  return localStorage.getItem('cpf')
}

export const setRegisterDate = date => {
  localStorage.setItem('date', date)
}

export const getRegisterDate = () => {
  return localStorage.getItem('date')
}

export const setRegisterPhone = phone => {
  localStorage.setItem('phone', phone)
}

export const getRegisterPhone = () => {
  return localStorage.getItem('phone')
}

export const setKeepConnectedStore = (keepConnected) => {
  return localStorage.setItem('keepConnected', keepConnected)
}

export const getKeepConnectedStore = (keepConnected) => {
  return localStorage.getItem('keepConnected')
}

export const setUserActive = (userActive) => {
  return localStorage.setItem('userActive', userActive)
}

export const getUserActive = () => {
  return localStorage.getItem('userActive')
}