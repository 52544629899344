import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'

import creditCard from '../assets/img/credit-card-payment.png'
import visa from '../assets/img/visa-logo.png'
import master from '../assets/img/master-logo.png'
import api from '../service/api'

import { usePopup } from '../contexts/PopupContext';

const useStyles = makeStyles({
  root: {
    backgroundColor: "#252525",
    borderRadius: 16,
  },
  colorLabel: {
    color: '#fff'
  }
});

const CardsUser = ({ card, handleRefresh, index }) => {
  const classes = useStyles()
  const { setPopup } = usePopup();

  const deletCard = async () => {
    try {
      await api.get(`/creditCard/delete?_id=${card._id}`)
      setPopup({
        type: 'success',
        message: 'Cartão deletado!',
        buttonAction: onClose
      })
    } catch (e) {
      setPopup({
        type: 'error',
        message: 'Erro ao deletar o cartão!',
        buttonAction: onClose
      })
    }
  }
  
  function onClose(){
    handleRefresh()
    setPopup('')
  }

  return (
    <Box display="flex" justifyContent="space-between" mt={2}>
      <Box>
        <Typography className={classes.colorLabel} style={{ fontFamily: 'Work Sans' }}
          >Cartão de crédito</Typography>
        <Typography style={{ fontFamily: 'Work Sans' }}
          color="textSecondary">{card.creditCardBrand}{' '}{card.creditCardNumber}</Typography>
      </Box>
      <Box>
        <img src={(card.creditCardBrand === 'Visa' && visa) || (card.creditCardBrand === 'Master' && master) ||  creditCard} width={30} alt="Cartão" />
        <IconButton color="primary" onClick={deletCard}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default CardsUser;