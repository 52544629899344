import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import api from '../service/api';
import jwt from 'jsonwebtoken';
import { getToken } from '../service/localStorage';
import moment from 'moment';
import InputMask from "react-input-mask";
import DatePicker from '../components/DatePicker';
import { usePopup } from '../contexts/PopupContext';
import erro from '../assets/icons/Ativo8.png';
import styles from '../styles/PopUp.module.css';
import sucess from '../assets/icons/Ativo7.png';

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    flexDirection: "column",
    maxWidth: "500px",
    height: '100%',

    [mediaQuery]:
    {
      padding: '25px',
    }
  },
  input: {
    backgroundColor: '#343434',
    borderRadius: 50,
    color: '#fff',
    textTransform: 'capitalize',
    fontFamily: 'Work Sans',
    width: '100%',
    maxWidth: 'none'
  },
  datePicker: {
    backgroundColor: '#343434',
    borderRadius: 50,
    color: '#fff',
    textTransform: 'capitalize',
    fontFamily: 'Work Sans',
    width: '100%',
    height: '56px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      padding: 0,
      color: 'rgba(255, 255, 255, 0.6)'
    },
    '& input': {
      fontFamily: 'Work Sans'
    }
  }, 
  disabledInput: {
    fontFamily: 'Work Sans',
    borderRadius: 50,
    backgroundColor: '#343434',
    opacity: '0.2',
    width: '100%',
    '& input': {
      color: 'white'
    }
  },
});

const MyData = ({ handleEditButton }) => {
  const { popup, setPopup } = usePopup();
  const classes = useStyles()
  const [userData, setUserData] = useState([]);
  const [birthDate, setBirthDate] = useState(null);
  const [cpf, setCpf] = useState(userData.cpf);
  const [cellphone, setCellphone] = useState(userData.cellphone);
  // const [popup, setPopup] = useState('');


  const { id } = jwt.decode(getToken('token'));

  useEffect(() => {
    async function getUser() {
      const res = await api.get(`user/get?_id=${id}`)
      setUserData(res.data);
      let bday = res.data.birthDate ? moment(res.data.birthDate) : null
      setBirthDate(bday)
    }
    getUser();
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      //let bday = `${birthDate.substring(6,10)}-${birthDate.substring(3,5)}-${parseInt(birthDate.substring(0,2)) + 1}` //yyyy-MM-dd
      let bday = moment(birthDate).add(1, 'days').format('yyyy-MM-DD')
      const res = await api.post('user/edit', { _id: id, birthDate: bday, cpf, cellphone });

      if (res.status === 200) {
        setPopup({type: 'success', message: 'Dados alterados com sucesso!'});
      }
    } catch (error) {
      setPopup({type: 'error', message: 'Erro ao editar os dados!'})
    }
  }

  function onClose(){
    setPopup('');
    handleEditButton();
  }

  if(popup === "ERROR"){
    return (
      <div className={styles.border}>
        <div className={styles.popUpContainer}>
          <div>
            <img alt="erro" src={erro}/>
          </div>
          <p>Erro ao editar os dados!</p>
          <div>
            <button onClick={onClose}>OK</button>
          </div>
        </div>
      </div>
    );
  }else if(popup === "SUCESS"){
    return (
      <div className={styles.border}>
        <div className={styles.popUpContainer}>
          <div>
            <img alt="success" src={sucess}/>
          </div>
          <p>Dados alterados com sucesso!</p>
          <div>
            <button onClick={onClose}>OK</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h5" color="primary">Meus dados</Typography>
      <Box mt={3} width="100%">
        <TextField
          placeholder="Email"
          variant="outlined"
          className={classes.disabledInput}
          value={userData.email}
          readOnly={true}
          disabled
        />
      </Box>
      <Box mt={2} width="100%">
        {/* <InputMask
          mask="99/99/9999"
          value={birthDate}
          onChange={(e) => console.log(e.target.value)}
          onBlur={() => { }}
          maskChar=" "
        >
          {inputProps =>
            <TextField
              {...inputProps}
              placeholder="Data de nascimento"
              className={classes.input}
              type="tel"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
            />
          }
        </InputMask> */}
        <DatePicker 
          outlined 
          value={birthDate} 
          setDate={(date) => setBirthDate(date)} 
          className={classes.datePicker} 
          iconColor='rgba(255, 255, 255, 0.3)'
          placeholder="Data de Nascimento"
        />
      </Box>
      <Box mt={2} width="100%">
        <InputMask
          fullWidth
          placeholder="CPF"
          mask="999.999.999-99"
          value={cpf || userData.cpf}
          onChange={e => setCpf(e.target.value)}
          className={classes.input}
          onBlur={() => { }} maskChar=" ">
          {inputProps =>
            <TextField
              {...inputProps}
              type="tel"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
            />
          }
        </InputMask>
      </Box>
      <Box mt={2} width="100%">
        <InputMask
          placeholder="Telefone"
          mask="(99) 99999-9999"
          value={cellphone || userData.cellphone}
          onChange={e =>  setCellphone(e.target.value)}
          className={classes.input}
          onBlur={() => { }} maskChar=" ">
          {inputProps =>
            <TextField
              {...inputProps}
              type="tel"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
            />
          }
        </InputMask>
      </Box>
      <Box mt={4} width='100%'>
        <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} onClick={handleSubmit} variant="contained" size="large" color="primary" fullWidth>
          Editar
        </Button>
      </Box>
      <Box mt={2} width='100%'>
        <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} variant="outlined" size="large" color="primary" fullWidth onClick={() => handleEditButton(5)}>
          Alterar senha
        </Button>
      </Box>
      <Box mt={2} display={{md: 'none'}} width='100%'>
        <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} variant="outlined" size="large" color="primary" fullWidth onClick={() => handleEditButton(null)}>
          Voltar
        </Button>
      </Box>
    </Box>

  )
}

export default MyData
