import React, { useState } from "react";

import "../styles/resetPassword.css";

import { useHistory } from "react-router-dom";
import { usePopup } from "../contexts/PopupContext";
import api from "../service/api";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const { setPopup } = usePopup();
  async function sendEmailResetPassword(e) {
    e.preventDefault();

    if (!email) {
      setPopup({
        type: "error",
        message: "O campo email é obrigatório",
      });
      return;
    }

    try {
      await api.get(`user/forgetPassword?email=${email}`);
      history.push("/forgot-password/success");
    } catch (error) {
      if (error.response.status === 400) {
        setPopup({
          type: "error",
          message: "O email informado não está cadastrado",
        });
      } else if (error.response.data.message) {
        setPopup({
          type: "error",
          message: error.response.data.message,
        });
      } else {
        setPopup({
          type: "error",
          message: "Não foi possível enviar o email de recuperação de senha",
        });
      }
    }
  }

  return (
    <>
      <div className="container-reset">
        <div className="card">
          <h1 className="para-reset-text">
            Entre com seu email cadastrado para redefinir sua senha
          </h1>
          <form onSubmit={sendEmailResetPassword}>
            <div className="content-input">
              <span className="label-input">Insira seu E-mail:</span>
              <input
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="link-verified">
              <button className="link-button" type="submit">
                Resetar minha senha
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
