import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import api from '../service/api';
import { clear } from '../service/localStorage';
import '../styles/emailVerified.css';

const EmailNonVerified = () => { 
  const [send, setSend] = useState(false)
  const [message, setMessage] = useState('Confira seu e-mail!')
  
  async function resendCreationEmail() {
    await api.get(`user/resendCreationEmail`).catch((error) => {
      let message = error?.response?.data?.error
      if(message != null){
        setMessage(message)
      }
    })

    setSend(true)
    clear()
  }

  return (
    <>
      <div className="container-verified">
        <div className="card">
          {send === true ? 
          <Typography
          variant="h4"
          color="primary"
          style={{ fontFamily: 'Work Sans', fontWeight: 700 }}
          >
              {message}
          </Typography>
            :
            (
              <>
                <Typography
                variant="h4"
                color="primary"
                style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 700 }}
                >
                Seu email ainda não foi verificado
                </Typography>
                <Typography
                      variant="h6"
                      color="primary"
                      style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#ffffff' }}
                    >
                Precisa de um novo código?
                </Typography>
                <div className="link-verified">
                  <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} onClick={resendCreationEmail} variant="contained" size="large" color="primary" fullWidth>
                    Enviar novo código
                  </Button>
                </div>
             </>
            )
          }
        </div> 
      </div>
    </>
  );
}

export default EmailNonVerified;