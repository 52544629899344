import React, { useState } from 'react'
import history from  '../history';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { formatHours } from '../helpers/date';
import Login from '../view/login';

import api from '../service/api';
import { getToken } from '../service/localStorage';
import jwt from 'jsonwebtoken';

import exemplo from '../assets/img/LogoEmBranco.png'

import moment from 'moment'

const useStyles = makeStyles({
  root: {
    // width: '100%',
  },
  media: {
    height: 340,
  },
  colorCard: {
    backgroundColor: '#252525'
  },
  cardRoot: {
    borderRadius: '13px 13px 13px 13px',
  }
});



const GameCardDetails = ({ game }) => {
  const classes = useStyles();
  const [isLoginVisible, setLoginVisible] = useState(false);

  const formatPrice = price => (price)?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const redirect = (path) => {
    if (getToken()) {
      const { id } = jwt.decode(getToken('token')); 
      async function getUser() {
        const res = await api.get(`user/get?_id=${id}`)
        if (res.data.active === false) {
          history.push('/user/non-verificated')
        } else {
          history.push(path);
          history.go();
        }
      }
      getUser();
    } else {
      setLoginVisible(true);
    }
  }

  return (
    <Box p={3} className={classes.root}>
      <Card className={classes.cardRoot}>
        <CardMedia        
          component="img"
          alt="Contemplative Reptile"
          image={game?.image || exemplo}
          className={classes.media}
        />
        <CardContent className={classes.colorCard}>
          <Box p={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5" color="primary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 500}}
              >
                {game?.name}
              </Typography>
              <Typography variant="h5" color="textPrimary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
                {formatHours(game?.startTime)}
              </Typography>
            </Box>
            <Box my={2} display="flex" justifyContent="space-between">
              <Typography variant="body1" color="textSecondary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
                {game?.local}
              </Typography>
              <Typography variant="body1" color="textSecondary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
                {moment(game?.startTime).format('DD/MM/YYYY')}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box width={'60%'}>
                <Typography variant="body2" color="textSecondary" component="p"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
                  {game?.description}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body1" align="right" color="primary"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
              >
                  R$ {formatPrice(game?.carPrice)}
                </Typography>
                <Typography variant="body2" color="textSecondary"
                  style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', fontWeight: 400}}
                  >
                  Ingressos a partir de:
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Button
          onClick={() => redirect(`/buy-ticket/${game._id}`)}
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#000000', fontWeight: 'bold'}}
        >
          Comprar
        </Button>
      </Card>
      <Login callBtn="none" visible={isLoginVisible}/>
    </Box>
  )
}

export default GameCardDetails
