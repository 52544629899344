import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { Avatar, Box, Link, Paper, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import avatarImg from '../assets/img/user.png'
import { clear, getToken } from '../service/localStorage';
import api from '../service/api';

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
  large: {
    width: 180,
    height: 180,
  },
  root: {
    backgroundColor: "#252525",
    width: 150,
    height: 150,
    marginTop: 32,
    fontFamily: 'Work Sans',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [mediaQuery]: {
      width: '130px',
      height: '130px'
    }
  },
})

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
    cursor: 'pointer'
  }
})(Typography);

const Profile = ({ handleEditButton }) => {
  const classes = useStyles()
  const history = useHistory()
  const [user, setUser] = useState('');
  const { id } = jwt.decode(getToken('token'));

  useEffect(() => {
    async function getUserId() {
      const res = await api.get(`user/get?_id=${id}`);
      setUser(res.data)
    }
    getUserId();
  }, [id]);

  const logoff = () => {
    clear()
    history.push("/home")
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Avatar alt="Avatar" src={avatarImg} className={classes.large} />
      <Box p={2}>
        <Typography
          style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#FFD500', fontWeight: 600 }}
          variant="h5" color="primary">{user.name}</Typography>
      </Box>
      <WhiteTextTypography
        style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#ffffff', fontWeight: 500 }}
        variant="subtitle1">{user.email}</WhiteTextTypography>

      <Box display="flex">
        <Box p={2}>
          <Paper elevation={3} className={classes.root}>
            <Box display="flex" justifyContent="center" alignItems="center" p={5} onClick={() => handleEditButton(1)}>
              <WhiteTextTypography variant="subtitle1" align="center"
                style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#ffffff', fontWeight: 500, padding: 20 }}
              >
                Meus dados
              </WhiteTextTypography>
            </Box>
          </Paper>
          <Paper elevation={3} className={classes.root}>
            <Box display="flex" justifyContent="center" p={5} onClick={() => handleEditButton(3)}>
              <WhiteTextTypography variant="subtitle1" align="center"
                style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#ffffff', fontWeight: 500, padding: 20 }}
              >
                Formas de pagamento
              </WhiteTextTypography>
            </Box>
          </Paper>
        </Box>
        <Box p={2}>
          <Paper elevation={3} className={classes.root}>
            <Box display="flex" justifyContent="center" p={5} onClick={() => handleEditButton(2)}>
              <WhiteTextTypography variant="subtitle1" align="center"
                style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#ffffff', fontWeight: 500, padding: 20 }}
              >
                Histórico de eventos
              </WhiteTextTypography>
            </Box>
          </Paper>
          <Paper elevation={3} className={classes.root}>
            <Box display="flex" justifyContent="center" p={5} onClick={() => handleEditButton(4)}>
              <WhiteTextTypography variant="subtitle1" align="center"
                style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#ffffff', fontWeight: 500, padding: 20 }}
              >
                Ative seu cupom
              </WhiteTextTypography>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box mt={1}>
        <Typography color="primary">
          <Link to="#" onClick={logoff} color="primary" underline="always"
            style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#FFD500', fontWeight: 500, padding: 20, cursor: 'pointer' }}
          >
            Sair
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default Profile
