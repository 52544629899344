import React from 'react';
import { useParams } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import valid from 'card-validator'
import history from '../history';

import style from '../styles/ConfirmCvv.module.css';
import '../styles/buyTicket.css';


import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
  TextField,
  DialogActions,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

import {
  ContentLeft,
  ContentRight,
  ContentWrap,
  EventDetails,
  Transport,
  Amount,
  Payment,
} from '../components'

import api from '../service/api';
import { getToken } from '../service/localStorage';
import InputMask from "react-input-mask";
import { usePopup } from '../contexts/PopupContext';

const useStyles = makeStyles({
  input: {
    color: '#000'
  }
});

const BuyTicket = () => {
  const {setPopup} = usePopup();
  const classes = useStyles()
  const params = useParams()
  const [ticket, setTicket] = React.useState({ transport: '', quantity: '', payment: '' })
  const [openCard, setOpenCard] = React.useState(false)
  const [game, setGame] = React.useState([])
  const [numberCard, setNumberCard] = React.useState('')
  const [nameCard, setNameCard] = React.useState('')
  const [validateCard, setValidateCard] = React.useState('')
  const [cvcCard, setCvcCard] = React.useState('')
  const [cpfCard, setCpfCard] = React.useState('')
  const [isSaveCard, setIsSaveCard] = React.useState(true)
  const [whichCard, setWhichCard] = React.useState([])
  const [confirmCvv, setConfirmCvv] = React.useState(false)
  // const [openCardBuy, setOpenCardBuy] = React.useState(false)
  // const [openSuccessBuy, setOpenSuccessBuy] = React.useState(false)
  // const [open, setOpen] = React.useState(false)
  // const [viewTicker, setViewTicker] = React.useState(false)
  // const [openErrorBuy, setOpenErrorBuy] = React.useState(false)

  const handleClickOpen = () => {
    setPopup({
      message: 'Deseja finalizar sua compra?',
      extraContent:
      <Box className="finalizar-btns-container">
        <Box minWidth={120}>
          <Button variant="contained" size="large" onClick={handleClose} color="primary" fullWidth
            style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', borderRadius: 50 }}
          >
            Não
          </Button>
        </Box>
        <Box minWidth={120}>
          <Button variant="contained" size="large" onClick={handleBuyButton} color="primary" fullWidth
            style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', borderRadius: 50 }}
          >
            Sim
          </Button>
        </Box>
      </Box>
    })
  }

  const handleClose = () => {
    setPopup('');
  }

  // const openPayment = () => {
  //   handleClickOpen()
  //   setIsSaveCard(true)
  // }

  const handleClickOpenCard = () => {
    setOpenCard(true);
  }

  const handleCloseCard = () => {
    setOpenCard(false);
  }
  const handleClickOpenCardBuy = () => {
    // setOpenCardBuy(true);
    setPopup({
      message: 'Processando...'
    })
  }

  // const handleCloseCardBuy = () => {
  //   setOpenCardBuy(false);
  // }

  const handleSuccessOpenCardBuy = () => {
    setPopup({  
      type: 'success', 
      message: 'Compra realizada com sucesso', 
      buttonText: 'Ver meus ingressos', 
      buttonAction: showTicket
    });
  }

  // const handleCloseSuccessCardBuy = () => {
  //   setOpenSuccessBuy(false);
  // }

  const handleErrorOpenCardBuy = () => {
    setPopup({
      type: 'error',
      message: 'Não foi possível concluir sua compra!'
    })
  }

  // const handleCloseErrorCardBuy = () => {
  //   setOpenErrorBuy(false);
  // }

  const openConfirmCvv = () => {
    setConfirmCvv(true)
    setIsSaveCard(false);
  }

  const closeConfirmCvv = () =>{
    setConfirmCvv(false)
  }

  React.useEffect(() => {
     if (getToken('token')) {
      const { id } = jwt.decode(getToken('token')); 
      async function getUser() {
        const res = await api.get(`user/get?_id=${id}`)
        if (res.data.active === false) {
          history.push('/user/non-verificated')
        }
      }
      getUser();
    }

    const getGame = async () => {
      const _id = params.id
      try {
        const res = await api.get('/event/get', { params: { _id } })
        if (res.data) {
          setGame(res.data)
        }
      } catch (error) {
        console.log(error.response)
      }
    }
    getGame()
 
  }, [params.id])
  const id = jwt.decode(getToken('token'))

  const validNumber = valid.number(numberCard)


  const brandBank = (e) => {
  
    if(e === 'visa'){
      return 'Visa'
    }
    if(e === 'aura'){
      return 'Aura'
    }
    if(e === 'discover'){
      return 'Discover'
    }
    if(e === 'mastercard'){
      return 'Master'
    }
    if(e === 'hipercard'){
      return 'Hipercard'
    }
    if(e === 'elo'){
      return 'Elo'
    }
    if(e === 'diners-club'){
      return 'Diners'
    }
    if(e === 'hiper'){
      return 'Hiper'
    }
    if(e === 'jcb'){
      return 'JCB'
    }
    if(e === 'american-express'){
      return 'Amex'
    }
    return e
  }


  const handleBuyButton = async () => {
    setConfirmCvv(false)
    handleClose(false)
    handleCloseCard(false)
    handleClickOpenCardBuy()

    if(!isSaveCard) {
      try {
        const res = await api.post('transaction/createTokenizedTransactionWithCreditCard',
          {
            _id: id.id,
            creditCardToken: whichCard.creditCardToken,
            creditCardNumber: whichCard.creditCardNumber,
            vehicleType: ticket.transport,
            creditCardSecurityCode: cvcCard,
            creditCardBrand: whichCard.creditCardBrand,
            amount:
              ticket.transport === 'car' ? game.carPrice * ticket.quantity * 100 : null ||
                ticket.transport === 'bus' ? game.busPrice * ticket.quantity * 100 : null ||
                  ticket.transport === 'van' ? game.vanPrice * ticket.quantity * 100 : null,
            installments: 1,
            saveCreditCard: true,
            eventId: game._id
          });
        if (res.status === 200) {
          handleSuccessOpenCardBuy(true)

        } else {
          handleErrorOpenCardBuy(true)
          setPopup({type: 'error', message: 'Compra não efetuada!'})
        }
      } catch (error) {
        handleErrorOpenCardBuy(true)
      }

    } else {
      try {
        const res = await api.post('transaction/createTransactionWithCreditCard',
          {
            _id: id.id,
            creditCardNumber: numberCard.replaceAll(' ', ''),
            vehicleType: ticket.transport,
            creditCardHolder: nameCard,
            creditCardExpirationDate: validateCard,
            creditCardSecurityCode: cvcCard,
            creditCardBrand: brandBank(validNumber.card?.type),
            amount:
              ticket.transport === 'car' ? game.carPrice * ticket.quantity * 100 : null ||
                ticket.transport === 'bus' ? game.busPrice * ticket.quantity * 100 : null ||
                  ticket.transport === 'van' ? game.vanPrice * ticket.quantity * 100 : null,
            installments: 1,
            saveCreditCard: true,
            eventId: game._id
          });
        if (res.status === 200) {
          handleSuccessOpenCardBuy(true)
          // history.push("/my-ticket")
        } else {
          handleErrorOpenCardBuy(true)

          setPopup({type: 'error', message: 'Compra não efetuada!'})
        }
      } catch (error) {
        handleErrorOpenCardBuy(true)

        setPopup({type: 'error', message: 'Compra não efetuada!'})
      }
    }
  }

  if(confirmCvv === true){
    return(
      <div className={style.confirmCvvBorder}>
        <div className={style.confirmCvvContainer}> 
          <p>Por favor <br/> Confirme o código de segurança do cartão</p>
          <div className={style.cvvContainerInput}> 
            <InputMask
              mask="999"
              onChange={e => setCvcCard(e.target.value)}
              onBlur={() => { }} maskChar=" ">
              {inputProps =>
                <TextField
                  {...inputProps}
                  fullWidth
                  type="tel"
                  required
                  label="CVV"
                  variant="outlined"
                  InputProps={{
                    classes: {
                      input: classes.input
                    }
                  }}
                />
              }
            </InputMask>
          </div>
          <div className={style.buttonContainer}>
            <button onClick={handleBuyButton}>Confirmar</button>
            <button onClick={closeConfirmCvv}>Cancelar</button>
          </div>
        </div>
      </div>
    );
  }

  const showTicket = () => {
    setPopup('')
    // handleCloseSuccessCardBuy(false)
    setPopup({
      message: 'Para visualizar seu ingresso, é necessário o download do aplicativo!',
      buttonAction: goToTickets,
      extraContent:       
        <Box p={1} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }} className="storeImgsContainer">
          <a href='https://play.google.com/store/apps/details?id=com.plax.parking&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noreferrer" className="appleStoreLink">
            <img width="200px" alt='Disponível no Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png'/>
          </a>

          <a href="https://apps.apple.com/br/app/fast-parking/id1549229777?itsct=apps_box&amp;itscg=30200" style={{display: 'inline-block', overflow: 'hidden', borderRadius: '13px', padding: '5px 0'}} target="_blank" rel="noreferrer">
            <img width="160px" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=250x83&amp;releaseDate=1613347200&h=aad69a80b849ae322b174592d78f3b40" alt="Download on the App Store"/>
          </a>
      </Box> 
    })
  }

  const goToTickets = () => {
    // setViewTicker(false);
    history.push('/my-ticket');
    history.go();
  }

  return (
    <>
      <ContentWrap>
          <ContentLeft>
            <EventDetails game={game} />
              <Box display={{md: 'none'}} className="left-container-responsive">
                {!ticket.transport && (
                  <Transport setTicket={setTicket} ticket={ticket} game={game} />
                )}
                {ticket.transport && !ticket.quantity && (
                  <Amount setTicket={setTicket} ticket={ticket} game={game} />
                )}
                {ticket.transport && ticket.quantity && !ticket.payment && (
                  <Payment handleClickOpenCard={handleClickOpenCard} handleOpenPayment={openConfirmCvv} handleWhichCard={setWhichCard} />
                )}
              </Box>
        </ContentLeft>
          <ContentRight display={{xs: 'none', md: 'block'}}>
            <Box>
              {!ticket.transport && (
                <Transport setTicket={setTicket} ticket={ticket} game={game} />
              )}
              {ticket.transport && !ticket.quantity && (
                <Amount setTicket={setTicket} ticket={ticket} game={game} />
              )}
              {ticket.transport && ticket.quantity && !ticket.payment && (
                <Payment handleClickOpenCard={handleClickOpenCard} handleOpenPayment={openConfirmCvv} handleWhichCard={setWhichCard} />
              )}
            </Box>
          </ContentRight>
      </ContentWrap>

      <Dialog fullWidth style={{maxWidth:"fit-content", margin: '0 auto'}} open={openCard} onClose={handleCloseCard} >
        <DialogContent style={{backgroundColor:'#D3D3D3'}}>
          <Typography variant="h5" color="primary"
            style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color:'#000'  }}>Adicionar cartão</Typography>
          <Box mt={2} maxWidth={300}>
            <Grid container spacing={1}>
              <Grid style={{width: '100%'}} item xs={12} >
                <InputMask mask="9999 9999 9999 9999" value={numberCard}
                  onChange={e => setNumberCard(e.target.value)} onBlur={() => { }} maskChar=" ">
                  {inputProps =>
                    <TextField
                      {...inputProps}
                      fullWidth
                      type="tel"
                      color="secondary"
                      required
                      label="Número do cartão"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.input
                        }
                      }}
                    />
                  }
                </InputMask>
              </Grid>
              <Grid style={{width: '100%'}} item xs={12}>
                <TextField
                  label="Nome impresso no cartão"
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  InputProps={{
                    classes: {
                      input: classes.input
                    }
                  }}
                  value={nameCard}
                  onChange={(e) => setNameCard(e.target.value)}
                  required
                />
              </Grid>
              <Grid style={{width: '100%'}} item xs={12}>

                <InputMask
                  mask="99/9999"
                  value={validateCard}
                  onChange={(e) => setValidateCard(e.target.value)}
                  onBlur={() => { }}
                  maskChar=" "
                >
                  {inputProps =>
                    <TextField
                      {...inputProps}
                      fullWidth
                      type="tel"
                      color="secondary"
                      required
                      label="Validade"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.input
                        }
                      }}
                    />
                  }
                </InputMask>
              </Grid>
              <Grid style={{width: '100%'}} item xs={12}>
                <InputMask
                  mask="999"
                  value={cvcCard}
                  onChange={e => setCvcCard(e.target.value)}
                  onBlur={() => { }} maskChar=" ">
                  {inputProps =>
                    <TextField
                      {...inputProps}
                      fullWidth
                      type="tel"
                      color="secondary"
                      required
                      label="CVV"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.input
                        }
                      }}
                    />
                  }
                </InputMask>
              </Grid>
              <Grid style={{width: '100%'}} item xs={12}>
                <InputMask
                  mask="999.999.999-99"
                  value={cpfCard}
                  onChange={e => setCpfCard(e.target.value)}
                  onBlur={() => { }} maskChar=" ">
                  {inputProps =>
                    <TextField
                      {...inputProps}
                      fullWidth
                      type="tel"
                      color="secondary"
                      required
                      label="CPF"
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.input
                        }
                      }}
                    />
                  }
                </InputMask>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{backgroundColor:'#D3D3D3'}}>
          <Button onClick={handleClickOpen} color="primary" variant="contained" fullWidth
            style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#000000', fontWeight: 700, borderRadius: 50 }}
          >
            Comprar
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Box p={3}>
            <Typography color="secondary" variant="h5" align="center"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans' }}>Deseja finalizar sua compra?</Typography>
          </Box>
          <Box p={3} display="flex" justifyContent="space-between" className="finalizar-btns-container">
            <Box minWidth={120}>
              <Button variant="contained" size="large" onClick={handleClose} color="primary" fullWidth
                style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', borderRadius: 50 }}
              >
                Não
              </Button>
            </Box>
            <Box minWidth={120}>
              <Button variant="contained" size="large" onClick={handleBuyButton} color="primary" fullWidth
                style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', borderRadius: 50 }}
              >
                Sim
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog> */}
      {/* <Dialog
        open={openSuccessBuy}
        onClose={handleCloseSuccessCardBuy}
      >
        <DialogContent>
          <Box p={3}>
            <Box p={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={successIcon} style={{ width: 100 }} alt="Success" />
            </Box>
            <Typography color="secondary" variant="h5" align="center"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans' }}>Compra Realizada com Sucesso</Typography>
          </Box>
          <Box p={4} className="ver-ingressos-btn">
            <Box>
              <DialogActions>
                <Button variant="contained" onClick={showTicket} color="primary" fullWidth
                  style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', borderRadius: 50, width: 360 }}
                >
                  Ver meus ingressos
                </Button>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog> */}
      {/* <Dialog
        open={openErrorBuy}
        onClose={handleCloseErrorCardBuy}
      >
        <DialogContent>
          <Box p={3}>
            <Box p={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={errorIcon} style={{ width: 100 }} alt="Error" />
            </Box>
            <Typography color="secondary" variant="h5" align="center"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans' }}>Não foi possível concluir sua compra!</Typography>
          </Box>
          <Box p={4}>
            <Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog> */}

      {/* <Dialog open={openCardBuy} onClose={handleCloseCardBuy}>
        <DialogContent>
          <Typography
            variant="h5"
            color="primary"
            style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', padding: 20 }}
          >
            Processando...
          </Typography>
        </DialogContent>
      </Dialog> */}

      {/* <Dialog
        open={viewTicker}
        onClose={goToTickets}
      >
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={goToTickets} style={{ marginTop: '-20px', marginRight: '-20px' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography
              color="secondary"
              variant="h5"
              align="center"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans' }}
            >
              Para visualizar seu ingresso, é necessário o download do aplicativo!
            </Typography>
          </Box>
          <Box p={3} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }} className="storeImgsContainer">
          
            <a href='https://play.google.com/store/apps/details?id=com.plax.parking&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" style={{padding: '5px 0'}}>
              <img width="200px" alt='Disponível no Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png'/>
            </a>

            <a href="https://apps.apple.com/br/app/fast-parking/id1549229777?itsct=apps_box&amp;itscg=30200" style={{display: 'inline-block', overflow: 'hidden', borderRadius: '13px', padding: '5px 0'}} target="_blank">
              <img width="160px" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=250x83&amp;releaseDate=1613347200&h=aad69a80b849ae322b174592d78f3b40" alt="Download on the App Store"/>
            </a>

          </Box>
        </DialogContent>
      </Dialog> */}
    </>
  )
}

export default BuyTicket
