/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line consistent-return
const InitialState = {
  sign: false,
};
export default function login(state = InitialState, action) {
  switch (action) {
    case 'SIGNIN':
      return {
        sign: action.sign,
      };
    default:
      return state;
  }
}
