import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import jwt from "jsonwebtoken";
import React, { useEffect } from "react";
import { Route, Router, Switch, useHistory } from "react-router-dom";
import "./App.css";
import MainLayout from "./components/Layout";
import {
  EmailNonVerified,
  EmailVerified,
  ForgotPassword,
  ForgotPasswordSuccess,
  RegisterSuccess,
  ResetPassword,
  ResetPasswordSuccess,
} from "./view";

import { Provider as AlertProvider, positions, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import api from "./service/api";
import {
  /*clear,*/ getKeepConnectedStore,
  getToken,
} from "./service/localStorage";

// import history from './history';
import PopupProvider from "./contexts/PopupContext";
import PrivacyPolicy from "./view/PrivacyPolicy";
import Soon from "./view/soon";

const options = {
  position: positions.BOTTOM_LEFT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffd500",
      contrastText: "#343434",
    },
    secondary: {
      main: "#343434",
    },
    text: {
      primary: "#ffffff",
      secondary: "#757575",
    },
    error: {
      main: "#f0683e",
    },
    divider: "#252525",
  },
  breakpoints: {
    values: {
      xs: 0,
      md: 858,
      sm: false,
      lg: false,
      xl: false,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        "&:hover": {
          color: "#343434",
        },
      },
    },
  },
});

const App = () => {
  const history = useHistory();
  useEffect(() => {
    if (getToken()) {
      const { id } = jwt.decode(getToken("token"));
      async function getUser() {
        const res = await api.get(`user/get?_id=${id}`);
        if (res.data.active === false) {
          history.push("/user/non-verificated");
        }
      }
      getUser();
    }

    async function connectedItem() {
      if (getKeepConnectedStore() === "false") {
        //Tava dando erro. Toda vez que o usuário atualizava a página, o storage era apagado e ficava sem o token da sessão.
        //window.addEventListener('beforeunload', clear());
      }
    }
    connectedItem();
  }, [history]);

  const RouteWrapper = ({ component: Component, layout: Layout, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <AlertProvider template={AlertTemplate} {...options}>
          <PopupProvider>
            <Router history={history}>
              <Switch>
                <RouteWrapper
                  exact
                  path="/"
                  layout={MainLayout}
                  component={Soon}
                />
                <RouteWrapper
                  exact
                  path="/home"
                  layout={MainLayout}
                  component={Soon}
                />
                <RouteWrapper
                  exact
                  path="/show-profile"
                  layout={MainLayout}
                  component={Soon}
                  isPrivate
                />
                <RouteWrapper
                  exact
                  path="/buy-ticket/:id"
                  layout={MainLayout}
                  component={Soon}
                  isPrivate
                />
                {/* <RouteWrapper exact path='/buy-ticket/:id' layout={MainLayout} component={BuyTicket} isPrivate /> */}
                <RouteWrapper
                  exact
                  path="/my-ticket"
                  layout={MainLayout}
                  component={Soon}
                  isPrivate
                />
                <RouteWrapper
                  exact
                  path="/email-verification/:token"
                  layout={MainLayout}
                  component={EmailVerified}
                />
                <RouteWrapper
                  exact
                  path="/user/non-verificated"
                  layout={MainLayout}
                  component={EmailNonVerified}
                />
                <RouteWrapper
                  exact
                  path="/user/passwordForget/:id"
                  layout={MainLayout}
                  component={ResetPassword}
                />
                <RouteWrapper
                  exact
                  path="/forgot-password"
                  layout={MainLayout}
                  component={ForgotPassword}
                />
                <RouteWrapper
                  exact
                  path="/forgot-password/success"
                  layout={MainLayout}
                  component={ForgotPasswordSuccess}
                />
                <RouteWrapper
                  exact
                  path="/reset-password/success"
                  layout={MainLayout}
                  component={ResetPasswordSuccess}
                />
                <RouteWrapper
                  exact
                  path="/register/success"
                  layout={MainLayout}
                  component={RegisterSuccess}
                />
                <RouteWrapper
                  exact
                  path="/search/:search"
                  layout={MainLayout}
                  component={Soon}
                />
                <RouteWrapper
                  exact
                  path="/privacy-policy"
                  layout={MainLayout}
                  component={PrivacyPolicy}
                />
              </Switch>
            </Router>
          </PopupProvider>
        </AlertProvider>
      </MuiThemeProvider>
    </>
  );
};

export default App;
