import React from 'react'

import '../styles/resetPassword.css';
import logoImg from '../assets/icons/logo_branca.png'

const ForgotPasswordSuccess = () => {
  return (
    <>
      <div className="container-reset">
        <div className="card">
          <img className="img-reset" src={logoImg} alt="Fast Parking"/>
          <h1 className="h1-reset">Confira seu E-mail</h1>
            <div className="content-input">
              <span >Te enviamos um e-mail para você resetar sua senha!</span>
            </div>
              
        </div>
      </div>
    </>
  );
 };

export default ForgotPasswordSuccess;