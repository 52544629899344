import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import api from '../service/api';
import '../styles/modalForm.css';

import {
  clear,
  getKeepConnectedStore,
  getRegisterCpf,
  getRegisterDate,
  getRegisterEmail,
  getRegisterName,
  getRegisterPassword,
  getRegisterConfirmPassword,
  getRegisterPhone,
  setKeepConnectedStore,
  setToken,
  setUserActive,
  setSessionToken
} from '../service/localStorage';
import { usePopup } from '../contexts/PopupContext';

const ModalForm = (props) => {
  const { 
    visible, 
    isLoginVisible, 
    setEmailError, 
    setPasswordError, 
    buttonLabel,
    className
  } = props

  const history = useHistory()
  const user_type = 'user'
  
  const { setPopup } = usePopup();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(visible);
  const [userActiveSt, setUserActiveSt] = useState('');

  useEffect(() => {
    setUserActive(userActiveSt);
  }, [userActiveSt]);

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  useEffect(() => {
    if(isLoginVisible) {
      isLoginVisible(modal);
    }
  }, [modal]);

  const toggle = () => {
    setModal(!modal);
  };

  const signIn = async () => {
    dispatch({
      type: 'SIGNIN',
      sign: true,
    });

    try {
      const email = getRegisterEmail()
      const password = getRegisterPassword()

      if(email === '') {
        setEmailError('Informe o e-mail');
        return;
      } else if(password === '') {
        setPasswordError('Informe a senha')
        return;
      }

      const connected = getKeepConnectedStore()
      const res = await api.get(`user/auth?email=${email}&password=${password}`)
      // window.location.reload();
      if (res.data.user.active === false) {
        setSessionToken(res.data.token)
        history.push('/user/non-verificated')
      } else {
        clear()
        setUserActiveSt(res.data.user.active)
        setToken(res.data.token)
        setKeepConnectedStore(connected)
        history.push("/home")
      }
      setModal(false)
    } catch (error) {
      const err = error.response?.data.error;
      console.log(error)
      let message = 'Erro ao efetuar login. Por favor, tente novamente.'
      if(err === 'User not found'){
        setEmailError("O e-mail informado não está cadastrado.");
        return;
      } else if (err === 'Bad password') {
        setPasswordError("A senha informada está incorreta.");
        return;
      }
      setPopup({type: "error", message: message})
    }
  }

  const newRegister = async () => {
    const name = getRegisterName();
    const email = getRegisterEmail();
    const password = getRegisterPassword();
    const confirmPassword = getRegisterConfirmPassword();
    const cpf = getRegisterCpf();
    const birthDate = getRegisterDate();
    const cellphone = getRegisterPhone();

    const message = 'Por favor, preencha o campo acima.';

    if(name === '') {
      props.setErrorMessage(prevValue => ({...prevValue, name: message}));
      return;
    } else if(email === '') {
      props.setErrorMessage(prevValue => ({...prevValue, email: message}));
      return;
    } else if(password === '') {
      props.setErrorMessage(prevValue => ({...prevValue, password: message}));
      return;
    } else if(confirmPassword === '') {
      props.setErrorMessage(prevValue => ({...prevValue, confirmPassword: message}));
      return;
    }

    if(password !== confirmPassword) {
      props.setErrorMessage(prevValue => ({...prevValue, confirmPassword: 'As senhas não conferem.'}));
      return;
    }

    const date = !birthDate ? '' : birthDate;

    try {
      await api.post('user/create', { name, email, password, cellphone, cpf, user_type, birthDate: date })
      clear()
      history.push("/register/success")
      toggle(true)
    } catch (error) {
      if(error.response.data.error === 'Registration failed: User already exist'){
        setPopup({type: 'error', message: 'O e-mail informado já está sendo utilizado.'})
      }
    }
  }

  return ( 
    <div>
      {props.callBtn === 'none' ? null
        :
        <Button onClick={() => toggle(false)} style={props.style}>
          {buttonLabel}
        </Button>
      }
      <Modal 
        onClosed={props.onClose} 
        isOpen={modal} 
        toggle={() => toggle(false)} 
        className={className} 
        autoFocus={false}
      >
        {props.header === 'none' ? null
          :
          <ModalHeader toggle={() => toggle(false)}></ModalHeader>
        }
        <ModalBody>
          {props.children}
        </ModalBody>
        {props.footer === 'none' ? null
          :
          <ModalFooter>
            {props.isLogin ?
              <Button className='BtbLogin' type="submit" style={{ width: '100%' }} onClick={signIn} block autoFocus={true}> Login </Button>
              : <Button className='BtbRegister' type="submit" style={{ width: '100%' }} onClick={newRegister} block>Cadastre-se</Button>}
          </ModalFooter>
        }
      </Modal>
    </div>
  );
};

export default ModalForm;
