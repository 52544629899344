import React from 'react'
import { Box, Typography, Button, Hidden } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import api from '../service/api'
import CardsUser from './CardsUser'

import { usePopup } from '../contexts/PopupContext';

import '../styles/paymentProfile.css';

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#252525",
    borderRadius: 16,
    [mediaQuery]: 
    { 
        padding: '5%' 
    }
  },
  colorLabel: {
    color: '#fff'
  }
});

const PaymentProfile = ({handleEditButton}) => {
  const classes = useStyles()
  const { setPopup } = usePopup();
  const [cards, setCards] = React.useState('')
  const [count, setCount] = React.useState(0);

  const handleRefresh = () => {
    setCount(count + 1);
  }

  React.useEffect(() => {
    const getCard = async () => {
      try {
        const res = await api.get('user/getSavedCreditCards', {params: {limit: 999}})
        setCards(res.data.creditCards)
      } catch (error) {
        // console.log(error.res)
        // if (error.res.status === 401) {
        //   clear()
        //   history.push("/home")
        // }
        setPopup({
          type: 'error',
          message: 'Erro ao carregar cartão!'
        })
      }
    }

    getCard()

    // eslint-disable-next-line
  }, [count])

  return (
    <Box p={5} className="paymentProfile-main-container">
      <Box p={5} className={classes.root} width="100%">
        <Box width="100%" >
          <Typography style={{ fontFamily: 'Work Sans' }}
            variant="h5" color="textPrimary">Forma de pagamento</Typography>

          <Box>
            {cards.length !== 0 ? cards.map(card => (
              <CardsUser handleRefresh={handleRefresh} key={card._id} card={card} />
            )) : <Typography color="secondary" variant="body2" align="center"
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#FFFFFF', padding: 10 }}>Você ainda não possui cartões salvos, realize sua primeira compra para visualizar seus cartões.</Typography>}
          </Box>
        </Box>
      </Box>
      <Hidden mdUp>
        <Box mt={4} width="80%">
              <Button className="voltar-btn" variant="outlined" size="large" color="primary" fullWidth onClick={() => handleEditButton(5)}>
                Voltar
              </Button>
        </Box>
      </Hidden>
    </Box>
  )
}

export default PaymentProfile
