import { Container, makeStyles } from "@material-ui/core";
import { useState } from "react";
import NavBar from "../view/NavBar";
import SearchResults from "../view/SearchResults";
import Popup from "./Popup";

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
  root: {
    height: "calc(100% - 72px)",
    width: "100%",
    position: "relative",
    padding: "0 2%",
    maxWidth: "1200px",
    overflowY: "auto",
    overflowX: "hidden",
    [mediaQuery]: {
      padding: "24px 16px",
      top: "72px",
      width: "100%",
    },
  },
});

const MainLayout = (props) => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState("");
  return (
    <>
      <Popup />
      <NavBar setSearchString={(value) => setSearchString(value)} />
      <Container className={classes.root}>
        {searchString !== "" ? (
          <SearchResults searchString={searchString} />
        ) : (
          props.children
        )}
      </Container>
    </>
  );
};

export default MainLayout;
