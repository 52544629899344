import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
    root: {
        width: "50%",
        [mediaQuery]: 
        { 
            width: '100%'
        }
    }
  });

const ContentRight = ({display, children, ...props }) => {
    const classes = useStyles();
    return (
        <Box display={display} className={classes.root} {...props}>{children}</Box>
    )
}

export default ContentRight
