import { createContext, useContext, useEffect, useState } from "react";

const PopupContext = createContext();

export const usePopup = () => {
    const context = useContext(PopupContext);
    const { visible, setVisible, popup, setPopup} = context;
    return { visible, setVisible, popup, setPopup}
}

const PopupProvider = ({children}) => {
    const [visible, setVisible] = useState(false);
    const [popup, setPopup] = useState('');

    useEffect(() => {
        if(popup === '') {
            setVisible(false)
        } else {
            setVisible(true)
        }
    }, [popup])

    return (
        <PopupContext.Provider
            value={{
                visible,
                setVisible,
                popup,
                setPopup
            }}
        >
            {children}
        </PopupContext.Provider>
    )
}

export default PopupProvider;