import React from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import exemplo from '../assets/img/exemplo.jpg'
import { formatFulldate, formatHours } from '../helpers/date';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 150,
  },
  colorCard: {
    borderRadius: 13,
    backgroundColor: '#252525',
  },
  ellipsis: {
    textOverflow: 'ellipsis', 
    overflow: 'hidden', 
    whiteSpace: 'nowrap',
  }
});

const GameMyCard = ({ eventParticipation, setMoreDetail }) => {
  const classes = useStyles()

  const handleMoreDetail = () => {
    setMoreDetail(eventParticipation)
  }

  return (
    <Box p={3} className='g-card-cont'>
      <Card className={classes.colorCard} >
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          image={eventParticipation?.permittedEvent?.image || exemplo}
          className={classes.media}
        />
        <CardContent >
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box style={{ color: '#ffd500' }} className={classes.ellipsis} >
                {eventParticipation?.eventName}
              </Box>
              <Typography variant="body1" color="textPrimary">
                {formatHours(eventParticipation?.permittedEvent?.startTime, 'HH:mm')}
              </Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Box style={{color: '#757575', fontSize: '0.875rem'}} className={classes.ellipsis} >
                {eventParticipation?.permittedEvent?.local}
              </Box>
              <Typography variant="body2" color="textSecondary">
                {formatFulldate(eventParticipation?.permittedEvent?.startTime)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box style={{color: '#757575', fontSize: '0.875rem'}} className={classes.ellipsis} >
                Pedido: {eventParticipation?.orderNumber}
              </Box>
              <Typography variant="body2" color="textSecondary">
                {formatFulldate(eventParticipation?.permittedEvent?.createdAt)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box style={{color: '#757575', fontSize: '0.875rem'}} className={classes.ellipsis} >
                Veiculo: {eventParticipation.vehicle_type === 'car' && 'Carro'}
                {eventParticipation.vehicle_type === 'van' && 'Van'}
                {eventParticipation.vehicle_type === 'bus' && 'Ônibus'} 
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          style={{ fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }}
          onClick={handleMoreDetail}
        >
          Mais detalhes
        </Button>
      </Card>
    </Box >
  )
}

export default GameMyCard
