import React from 'react'
import { Box, Card, CardContent, CardMedia, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import exemplo from '../assets/img/LogoEmBranco.png'

const useStyles = makeStyles({
  media: {
    height: 300,
  },
  colorCard: {
    backgroundColor: '#252525',
    borderRadius: 13,
  },
});

const EventDetails = ({ game }) => {
  const classes = useStyles()
  return (
    <>
      <Typography color="primary" variant="h4"
        style={{ fontFamily: 'Work Sans'}}
        >
        Evento
      </Typography>
      <Box mt={2}>
        <Card className={classes.colorCard}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            image={game?.image|| exemplo}
            className={classes.media}
          />
          <CardContent>
            <Box p={2}>
              <Typography variant="h5" color="primary"
              style={{ fontFamily: 'Work Sans'}}
              >
                {game?.name}
              </Typography>
              <Box mt={2}>
                <Typography variant="body1">
                  {game?.local}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary" component="p"
                style={{ fontFamily: 'Work Sans'}}
                >
                  {game?.description}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

export default EventDetails
