import React, { useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";
import logoImg from "../assets/icons/logo_branca.png";
import { usePopup } from "../contexts/PopupContext";
import api from "../service/api";
import "../styles/resetPassword.css";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
  const history = useHistory();
  const query = useQuery();
  const { id } = useParams();
  const { setPopup } = usePopup();
  const [password, setPassword] = useState(undefined);
  const [confirmPassoword, setConfirmPassword] = useState(undefined);

  const SendNewPassword = async (e) => {
    e.preventDefault();

    const token = query.get("token");
    if (!token) {
      setPopup({
        type: "error",
        message: "Token inválido",
      });
      return;
    }

    if (!password || !confirmPassoword) {
      setPopup({
        type: "error",
        message: "O campo senha é obrigatório",
      });
      return;
    }

    if (password !== confirmPassoword) {
      setPopup({
        type: "error",
        message: "As senhas não coincidem",
      });
      return;
    }

    try {
      await api.post(
        "user/changeForgottenPassword",
        {
          _id: id,
          newPassword: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      history.push("/reset-password/success");
    } catch (error) {
      setPopup({
        type: "error",
        message: error.response.data.error,
      });
    }
  };

  return (
    <>
      <div className="container-reset">
        <div className="card">
          <img className="img-reset" src={logoImg} alt="Fast Parking" />
          <h1 className="h1-reset">Resetar senha!</h1>
          <form onSubmit={SendNewPassword}>
            <div className="content-input">
              <span className="label-input" htmlFor="password">
                Nova senha:
              </span>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Nova Senha"
                required
              />
            </div>
            <div className="content-input">
              <span className="label-input" htmlFor="password-confirm">
                Repetir nova senha:
              </span>
              <input
                type="password"
                placeholder="Repetir Nova Senha"
                required
                value={confirmPassoword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="link-verified">
              <button className="link-button" type="submit">
                Resetar Senha
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
