import React, { useEffect } from 'react';
import jwt from 'jsonwebtoken';

import {
  Box,
  TextField,
  Grid,
  Divider,
  Typography,
  Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'

import {
  ContentLeft,
  ContentRight,
  ContentWrap,
  GameMyCard,
  MyGameCardDetails,
} from '../components'

import { getToken } from '../service/localStorage';
import api from '../service/api';
import { useHistory } from 'react-router-dom';
import SearchResults from './SearchResults';
import useMediaQuery from '../hooks/useMediaQuery';

import '../styles/myTicket.css';
import ModalForm from '../components/ModalForm';

const mediaQuery = '@media (max-width: 858px)';

const useStyles = makeStyles({
  input: {
    backgroundColor: '#343434',
    borderRadius: 23,
  },
  colorText: {
    color: "white",
  },
  inputSearch: {
    backgroundColor: 'rgba(37, 37, 37, 0.5)',
    borderRadius: 25,
    padding: 20,
    width: 250,
    height: 8,
    border: 'none',
    color: '#FFFFFF',
    marginRight: 20,
    fontFamily: 'Work Sans',
  },
  buttonSearch: {
    position: 'relative',
    background: 'none',
    border: 'none',
    color: '#FFFFFF',
    marginLeft: -45,
    right: 10,
  },
  modal: {
    backgroundColor: 'red'
  },
  divider: {
    [mediaQuery]: {
      display: 'none'
    }
  }
});

const MyTicket = () => {
  const isMobile = useMediaQuery();

  const classes = useStyles()
  const [eventsParticipations, setEventsParticipations] = React.useState([]);
  const [moreDetail, setMoreDetail] = React.useState('');
  const [searchText, setSearchText] = React.useState("")
  const [data, setData] = React.useState(eventsParticipations);
  const [hasNextPage, setHasNextPage] = React.useState(false)
  const [page, setPage] = React.useState(1);
  
  const { id } = jwt.decode(getToken('token'));
  
  const handleChange = value => {
    setSearchText(value);
    filterData(value);
  };
  
  const filterData = async (value) => {
    async function filterCoupons(){
      const res = await api.get(`user/searchCoupons?_id=${id}&isUsed=false&eventName=${value}&page=1&limit=10`)
      res.data.coupons.sort((a, b) => a.permittedEvent.startTime > b.permittedEvent.startTime ? 1 : -1)
      setData(res.data.coupons)
    }
    await filterCoupons()
  }
  
  const history = useHistory();

  useEffect(() => {
    data.sort((a, b) => a.permittedEvent.startTime > b.permittedEvent.startTime ? 1 : -1)
  }, [data])
  
  React.useEffect(() => { 
    if (getToken()) {
      const { id } = jwt.decode(getToken('token')); 
      async function getUser() {
        const res = await api.get(`user/get?_id=${id}`)
        if (res.data.active === false) {
          history.push('/user/non-verificated')
        }
      }
      getUser();
    }
    
    async function getTransactions() {
      const res = await api.get(`user/getCoupons`, { params: { _id: id, isUsed: false, page: 1} }); 
      setHasNextPage(res.data.hasNextPage);
      setEventsParticipations(res.data.coupons);
      setData(res.data.coupons);
      if(res.data.coupons.length > 0 && !isMobile){
        setMoreDetail(res.data.coupons[0])
      }
    };
    getTransactions();
  }, [id, history]);
  
  React.useEffect(() => {
    async function getCoupons() {
      const res = await api.get(`user/getCoupons`, { params: { _id: id, isUsed: false, page: page} });
      let all = eventsParticipations.concat(res.data.coupons);
      setEventsParticipations(all);
      setData(all)
      setHasNextPage(res.data.hasNextPage)
    }
    if(hasNextPage)
      getCoupons()
  }, [page])

  return (
    <>
      <ContentWrap>
        <ContentLeft>
          <Box className="busca-responsive">
            <input className={classes.inputSearch} placeholder="Buscar..." value={searchText} onChange={(e) => handleChange(e.target.value)} required/>
            <button className={classes.buttonSearch} type="submit" ><SearchIcon /></button>
          </Box>
            <Box display={{xs: 'none', md:'block'}} p={2} width="100%">
              <TextField
                color="primary"
                fullWidth
                label="Buscar"
                variant="outlined"
                className={classes.input}
                value={searchText}
                onChange={e => handleChange(e.target.value)}
                InputProps={{
                  endAdornment: <SearchIcon />
                }}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
              />
            </Box>

          {searchText !== '' && isMobile ?

            <SearchResults events={data} searchString={searchText}/>

            :
        
            <Box className="ticket-grid">
              <Grid container className="responsive-grid" spacing={2}>
                {!!data.length ? data.map((eventParticipation, index) => (
                  <Grid item key={index} className="game-card" xs={6}>
                    <GameMyCard eventParticipation={eventParticipation} key={eventParticipation._id} setMoreDetail={setMoreDetail} />
                  </Grid>
                )) : <Typography
                color="secondary"
                variant="h4"
                align="center"
                style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#FFF', padding: 10 }}
                >
                  Ops, você ainda não possui ingressos
                </Typography>}
                <Box display="flex" justifyContent="center" alignItems="center" mt={2} width="100%">
                    { hasNextPage ? 
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', borderRadius: 50}}
                        onClick={() => { setPage(page+1) }}
                      >
                        Ver mais
                      </Button> : ''
                    }
                </Box>                  
              </Grid>
              {isMobile ?
                <ModalForm  callBtn="none" className='modalDetails' header="none" footer="none" visible={moreDetail !== ''} onClose={() => setMoreDetail('')}>
                  {moreDetail && <MyGameCardDetails btnVoltar={true} onVoltar={() => setMoreDetail('')} moreDetail={moreDetail} />}
                </ModalForm>
                : null
              }
            </Box>
          }
        </ContentLeft>
          <Divider className={classes.divider} display={{xs: 'none', md:'block'}} orientation="vertical" bgcolor="primary" flexItem />
          <ContentRight display={{xs: 'none', md:'block'}} p={2} width="100%">
            <Box width="50%" position='fixed' style={{paddingRight: '10%'}}>
              {moreDetail && <MyGameCardDetails moreDetail={moreDetail} />}
            </Box>
          </ContentRight>
      </ContentWrap>
    </>
  )
}

export default MyTicket