import React, { useState } from 'react'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import api from '../service/api';
import { usePopup } from '../contexts/PopupContext';

const mediaQuery = '@media (max-width: 858px)';

const useStyles = makeStyles({
  root: {
    backgroundColor: "#252525",
    borderRadius: 16,
  },
  input: {
    backgroundColor: '#141414',
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [mediaQuery]: {
      display: 'unset',
      padding: 0
    }
  },
  inputContainer: {
    width: '350px',
    [mediaQuery]: {
      width: '100%',
    }
  }
});


const ActivateCoupon = ({handleEditButton}) => {
  const { setPopup } = usePopup(); 
  const classes = useStyles()
  const [exchangeCode, setExchangeCode] = useState('');

  async function HandleSendCode() {
    try {
      await api.get(`coupon/activateCoupon?exchangeCode=${exchangeCode}`);
      setPopup({
        type: 'success',
        message: 'Cupom ativado!'
      });
      setExchangeCode('')
    } catch (error) {
      setPopup({
        type: 'error',
        message: 'Erro ao ativar cupom!'
      });
    }
  }

  return (
    <Box p={5} mt={2} className={classes.mainContainer}>
      <Box p={5} className={classes.root}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mt={3} maxWidth={260}>
            <Typography
            style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#FFD500', fontWeight: 500, padding: 20 }}
            variant="h5" align="center">Ative o seu cupom</Typography>
            <Typography
            style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#FFFFFF', fontWeight: 300}}
            variant="body2" align="center">Entre com seu código e ative seu cupom. Seu ingresso poderá ser visualizado em "ingressos"</Typography>
          </Box>
          <Box p={2} className={classes.inputContainer}>
            <TextField
              fullWidth
              label="Código"
              variant="outlined"
              className={classes.input}
              value={exchangeCode}
              onChange={(e) => setExchangeCode(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} onClick={HandleSendCode} variant="contained" size="large" color="primary" fullWidth>
              Ativar
            </Button>
          </Box>
          {/* <Box mt={2} maxWidth={200}>
            <Typography
            style={{ lineHeight: 1, fontFamily: 'Work Sans', color: '#FFFFFF', fontWeight: 300}}
            variant="body2" align="center">* uma vez ativa o cupom é intransferível</Typography>
          </Box> */}
        </Box>
      </Box>
      <Box mt={2} display={{md: 'none'}} width="50%" margin="16px auto">
        <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} variant="outlined" size="large" color="primary" fullWidth onClick={() => handleEditButton()}>
          Voltar
        </Button>
      </Box>
    </Box>
  )
}

export default ActivateCoupon
