import axios from 'axios';
import { getSessionToken, getToken } from './localStorage';

const api = axios.create({
  baseURL: 'https://api.fastparking.com.br/',
  // baseURL: 'https://backend-fastparking-ric.herokuapp.com',
  // baseURL: 'http://64.225.27.31:3333',
});

api.interceptors.request.use(async (config) => {
  const token = getToken()
  const sessionToken = getSessionToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  else if(sessionToken){
    config.headers.Authorization = `Bearer ${sessionToken}`;
  }
  return config;
});

export default api
