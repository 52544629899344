import React from 'react'
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import car from '../assets/icons/car.png'
import carAcitive from '../assets/icons/car-active.png'
import van from '../assets/icons/van.png';
import vanAcitive from '../assets/icons/van-active.png'
import bus from '../assets/icons/bus.png'
import busAcitive from '../assets/icons/bus-active.png'

import { formatHours, formatFulldate } from '../helpers/date';

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#252525",
    borderRadius: 16,
  },
  mainContainer: {
    [mediaQuery]: {
      padding: 0,
    }
  }
});

const Transport = ({ setTicket, ticket, game }) => {
  const classes = useStyles()
  const [vehicle, setVehicle] = React.useState('car')

  return (
    <Box p={5} mt={2} className={classes.mainContainer}>
      <Box p={5} className={classes.root}>
        <Box>
          <Typography align="center" variant="h2" color="textPrimary"
              style={{ fontFamily: 'Work Sans'}}
              >
            {formatHours(game?.startTime)}
          </Typography>
          <Typography align="center" variant="h4" color="textSecondary"
              style={{ fontFamily: 'Work Sans'}}
              >
            {formatFulldate(game?.startTime)}
          </Typography>
        </Box>
        <Box mt={5}>
          <Typography align="center" variant="h5" color="primary"
              style={{ fontFamily: 'Work Sans', fontWeight: 500}}
              >
            Qual Transporte será utilizado?
        </Typography>
          <Box mt={1}>
            <Typography align="center" variant="h6" color="textSecondary"
              style={{ fontFamily: 'Work Sans'}}
              >
              Preços variam para cada opção
          </Typography>
          </Box>
        </Box>
        <Box mt={5}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <IconButton variant="contained" onClick={() => setVehicle('car')} >
                {vehicle === 'car' ? (<img src={carAcitive} width={60} alt="car" />) : (<img src={car} width={60} alt="car" />)}
              </IconButton>
              <Typography align="center" color="textSecondary"
              style={{ fontFamily: 'Work Sans'}}
              >Carro</Typography>
            </Grid>
            <Grid item>
              <IconButton variant="contained" onClick={() => setVehicle('van')} >
                {vehicle === 'van' ? (<img src={vanAcitive} width={60} alt="van" />) : (<img src={van} width={60} alt="van" />)}
              </IconButton>
              <Typography align="center" color="textSecondary"
              style={{ fontFamily: 'Work Sans'}}
              >Van</Typography>
            </Grid>
            <Grid item>
              <IconButton variant="contained" onClick={() => setVehicle('bus')} >
              {vehicle === 'bus' ? (<img src={busAcitive} width={60} alt="bus" />) : (<img src={bus} width={60} alt="bus" />)}
              </IconButton>
              <Typography align="center" color="textSecondary"
              style={{ fontFamily: 'Work Sans'}}
              >Ônibus</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5} display="flex" justifyContent="center">
          <Box minWidth={350} display="flex" justifyContent="center">
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              onClick={() => { setTicket({ ...ticket, 'transport': vehicle })}}
              style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', borderRadius: 50, width: '80%'}}
            >
              Seguinte
          </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Transport
