import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { usePopup } from '../contexts/PopupContext';

import api from '../service/api'
import CardsUser from './CardsUser'

import { clear } from '../service/localStorage'

const mediaQuery = "@media (max-width:858px)";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#252525",
    borderRadius: 16,
  },
  colorLabel: {
    color: '#fff'
  }, 
  checkbox: {
    border: '1px solid #ffd500',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    cursor: 'pointer',
  },
  mainContainer: {
    [mediaQuery]: {
      padding: 0
    }
  },
  btnContainer: {
    [mediaQuery]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  btn: {
    [mediaQuery]: {
      width: '100%',
      margin: 0,
      spacing: 1,
      '&:last-child': {
        marginTop: '10px'
      }
    }
  }
});

const Payment = ({ handleClickOpenCard, handleOpenPayment, handleWhichCard }) => {
  const classes = useStyles()
  const history = useHistory()
  const { setPopup } = usePopup();
  const [value, setValue] = React.useState('')
  const [cards, setCards] = React.useState([])
  const [counter, setCounter] = React.useState(0)

  const handleChange = (event) => {
    let index = parseInt(event.target.id)
    setValue(index)
    handleWhichCard(cards[index])
  }

  const refresh = () => {
    setCounter(counter + 1);
  }

  React.useEffect(() => {
    const getCard = async () => {
      try {
        const res = await api.get('user/getSavedCreditCards')

        if (res.data.creditCards.length) {
          setCards(res.data.creditCards)
        }
      } catch (error) {
        console.log(error.response)
        if (error.response.status === 401) {
          clear()
          history.push("/home")
        }
        setPopup({
          type: 'error',
          message: 'Erro ao carregar cartão!'
        })
      }
    }

    getCard()

    // eslint-disable-next-line
  }, [counter])

  return (
    <Box p={5} mt={2} className={classes.mainContainer}>
      <Box p={5} className={classes.root}>
        <Box >
          <Typography style={{ fontFamily: 'Work Sans' }}
            variant="h5" color="textPrimary">Forma de pagamento</Typography>
          <Box>
            {cards.length ? (
              <Box>
                {cards.map((card, index) => (
                  <Box display="flex" alignItems="center">
                    <Box className={classes.checkbox} mr={1} style={{backgroundColor: index === value ? '#ffd500' : 'transparent'}}  
                      onClick={handleChange} id={index}></Box>
                    <Box width="100%"><CardsUser card={card} handleRefresh={refresh} index={index} /></Box>
                  </Box>
                ))}
              </Box>
            ) : (
                <Typography
                  color="secondary"
                  variant="body2"
                  align="center"
                  style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#FFFFFF', padding: 10 }}
                >
                  Você ainda não possui cartões salvos, realize sua primeira compra para visualizar seus cartões.
                </Typography>
              )}
          </Box>
          <Box mt={4} display="flex" flexDirection="column" alignItems="center" color="primary" className={classes.btnContainer}>
            <Box minWidth="190px" width="50%" className={classes.btn}>
              <Button variant="contained" size="large" fullWidth onClick={handleClickOpenCard}
                style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#000000', fontWeight: 700, borderRadius: 50 }}
              >
                + Adicionar cartão
              </Button>
            </Box>
            <Box minWidth="190px" width="50%" mt={1} className={classes.btn}>
              <Button variant="contained" size="large" color="primary" fullWidth onClick={handleOpenPayment}
                style={{ textTransform: 'capitalize', fontFamily: 'Work Sans', color: '#000000', fontWeight: 700, borderRadius: 50 }}
                disabled={!cards.length}
              >
                comprar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Payment
