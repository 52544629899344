import { Button, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import logoImg from "../assets/icons/logo_branca.png";
import { clear } from "../service/localStorage";
import "../styles/resetPassword.css";
const ResetPasswordSuccess = () => {
  const history = useHistory();
  function goToHome(e) {
    e.preventDefault();
    clear();
    history.push("/");
  }

  return (
    <>
      <div className="container-reset">
        <div className="card">
          <img className="img-reset" src={logoImg} alt="Fast Parking" />
          <Typography
            variant="h4"
            color="primary"
            style={{
              textTransform: "capitalize",
              fontFamily: "Work Sans",
              fontWeight: 700,
            }}
          >
            Senha Resetada Com Sucesso
          </Typography>
          <div className="link-verified">
            <Button
              style={{
                borderRadius: 50,
                fontFamily: "Work Sans",
                textTransform: "capitalize",
                fontWeight: 600,
              }}
              onClick={goToHome}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
            >
              Ir para início
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordSuccess;
