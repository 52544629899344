import React, { useEffect, useState } from 'react'
import jwt from 'jsonwebtoken';

import { Box, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import api from '../service/api';
import { getToken } from '../service/localStorage';

import { usePopup } from '../contexts/PopupContext';

const useStyles = makeStyles( theme => ({
  input: {
    backgroundColor: '#343434',
    borderRadius: 23,
    width: '100%'
  },
  errorMessage: {
    fontSize: '12px',
    color: theme.palette.error.main,
    padding: '6px 12px'
  }
}));

const ChangePassword = ({handleEditButton}) => {
  const classes = useStyles();
  const { setPopup } = usePopup();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { id } = jwt.decode(getToken('token'));

  useEffect(() => {
    setErrorMessage('');
  }, [newPassword, oldPassword, confirmPassword]);

  const onFinish = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setPopup('');
    handleEditButton(null)
  }

  async function handleSubmit(e) {

    if(oldPassword === '' || newPassword === '' || confirmPassword === ''){
      setErrorMessage('Todos os campos devem ser preenchidos.');
      return;
    } else if(newPassword !== confirmPassword) {
      setErrorMessage('Os campos de senha e confirmação de senha não conferem.');
      return;
    }

    e.preventDefault();
    try {
      await api.post('user/changePassword', { _id: id, oldPassword, newPassword });
      setPopup({
        type: 'success',
        message: 'Senha alterada com sucesso!',
        buttonAction: onFinish
      });
    } catch(e) {
      if(e.response?.data.error === 'Bad password') {
        setErrorMessage('A senha atual está incorreta.');
        return;
      }
      setPopup({
        type: 'error',
        message: 'Alteração de senha não realizada.'
      });
    }
  }

  return (
    <Box display="flex" justifyContent="center" mt={15}>
      <Box maxWidth="250px" width="60%">
        <Typography variant="h5" color="primary">Alterar senha</Typography>
        <Box mt={3}>
          <TextField
            label="Senha atual"
            variant="outlined"
            className={classes.input}
            type="password"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          <TextField
          label="Nova senha"
          variant="outlined"
          className={classes.input}
          type="password"
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        </Box>
        <Box mt={2}>
          <TextField
            label="Confirmação de senha"
            variant="outlined"
            className={classes.input}
            type="password"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Box>
        <Box className={classes.errorMessage}>
          {errorMessage}
        </Box>
        <Box mt={4}>
          <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} onClick={ handleSubmit } variant="contained" size="large" color="primary" fullWidth>
            Salvar
          </Button>
        </Box>
        <Box mt={2} display={{md: 'none'}} width='100%'>
          <Button style={{ borderRadius: 50, fontFamily: 'Work Sans', textTransform: 'capitalize', fontWeight: 600 }} variant="outlined" size="large" color="primary" fullWidth onClick={() => handleEditButton(null)}>
            Voltar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ChangePassword
