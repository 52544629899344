import React, { useState } from 'react'

import {
  Input,
  ModalBody,
} from 'reactstrap'

import '../styles/login.css';
import fastParking from '../assets/icons/logo_black.svg'
import ModalForm from '../components/ModalForm.jsx'
import { setKeepConnectedStore, setRegisterEmail, setRegisterPassword } from '../service/localStorage'
import history from '../history';

const Login = (props) => {
  const [keepConnected, setKeepConnected] = useState(false);
  const [email, setEmail] = React.useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [password, setPassword] = React.useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [visible, setVisible] = React.useState(props.visible ? props.visible : true);

  React.useEffect(() => {
    setRegisterEmail(email)
    setRegisterPassword(password)
    setKeepConnectedStore(keepConnected)
  }, [email, password, keepConnected]);

  React.useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  React.useEffect(() => {
    setErrorEmail('');
  }, [email])
  
  React.useEffect(() => {
    setErrorPassword('');
  }, [password])

  React.useEffect(() => {
    if(props.isLoginVisible){
      props.isLoginVisible(visible);
    }
  }, [visible])

  const onEsqueceuSenha = () => {
    setVisible(false);
    history.push('/forgot-password');
    history.go();
  }

  return (
    <div className='Login' >
      <ModalForm setEmailError={setErrorEmail} setPasswordError={setErrorPassword} callBtn={props.callBtn} button={true} visible={visible} isLoginVisible={(value) => setVisible(value)} buttonLabel={'Login'} style={{
        backgroundColor: '#FFF', color: '#252525', width: '150px', borderRadius: '20px', height: '38px', marginRight: '20px', fontFamily: 'Work Sans'
      }} isLogin={true}>
        <div className='LogoLogin' style={{ display: 'flex', justifyContent: 'center' }}>
          <img style={{ width: '90%'}} src={fastParking} alt="Fast Parking" />
        </div>
        <ModalBody>
          <div style={{
            color: '#FFD500', textAlign: 'center', fontSize: '1.8rem', fontFamily: 'Work Sans', 
          }}>
            Efetuar Login
          </div>
          <div className='Input'>
            <Input className='Email' style={{ marginTop: '5%', width: '100%' }} placeholder='E-mail' value={email} onChange={(e) => setEmail(e.target.value)} />
            <div className="errorMessage">
              {errorEmail}
            </div>
            <Input className='Password' type='password' style={{ marginTop: '5%', width: '100%' }} placeholder='Senha' value={password} onChange={(e) => setPassword(e.target.value)} />
            <div className="errorMessage">
              {errorPassword}
            </div>
          </div>
          <div className='BottomCard'>
            <button className='ForgotPassword' style={{ fontSize: '1rem', color: '#FFD500' }} onClick={onEsqueceuSenha}>Esqueceu a Senha</button>
            <div className='KeepConnected'>
              Manter Conectado
              <input type="checkbox" style={{ marginTop: '8px' }} defaultChecked={keepConnected} onChange={() => setKeepConnected(!keepConnected)} />
            </div>
          </div>
        </ModalBody>
      </ModalForm>
    </div>
  );
};

export default Login;
