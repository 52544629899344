import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br'); 

export const formatHours = date => {
  if (!date) return '' 
  return moment(date).utcOffset(0).format('HH:mm')
}

export const formatFulldate = date => {
  if (!date) return '' 
  return moment(date).utcOffset(0).format('DD/MM/YYYY')
}

export const formatSemDate = date => {
  if (!date) return '' 
  return moment(date).utcOffset(0).format('dddd')
}
